import React from "react";
import { Helmet } from "react-helmet";
import {
  BaseForm,
  CategorySearch,
  Input,
  ImageUpload,
  Checkbox,
  Searchable,
  MultiTextInput,
  BrandSearch,
  VALIDATION_TYPES,
  Toggle,
  Textarea,
  Select,
} from "../../../../components/Form";
import MetaDataFormFields from "../../../../components/MetaDataFormFields";
import Tabs from "../../../../components/Tabs";
import Loader from "../../../../components/Loader";
import { Dialog, Popup } from "../../../../components/Popup";
import { getMessage } from "../../../../lib/translator";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import {
  getSession,
  isExtensionEnabled,
  getStores,
} from "../../../../lib/auth";
import API from "../../../../lib/api";
import { get, unset } from "../../../../lib/storage";
import getDefaultStore from "../../../../containers/StoreSelector/getDefaultStore";

import deleteIcon from "./icon-dustbin.svg";
import shopIcon from "./shop.svg";
import editIcon from "./edit.svg";
import "./style.css";
import RichEditor, {
  exportText,
  importText,
} from "../../../../components/RichEditor";
import {
  isMarketPlaceOwner,
  isMarketPlaceSeller,
} from "../../../../lib/marketPlace";
import { getProductRequestStatus, isSellerSection } from "..";
import {
  convertToISODuration,
  parseISODuration,
} from "../../../../lib/commonlyused";

const FREQUENCY_TYPE = [
  {
    text: "Day",
    value: "Day",
  },
  {
    text: "Month",
    value: "Month",
  },
  {
    text: "Year",
    value: "Year",
  },
];

export const fetchClientItemLabel = (code) => {
  let label;
  switch (code) {
    case "PH":
      label = "SKU Code";
      break;
    default:
      label = "Client Item Id";
      break;
  }
  return label;
};

export default class ProductForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state.selectedVariant = 0;
    this.state.variantName = "";
    this.state.variantFields = [];
    this.state.editVariantFields = [];
    this.state.productVariantProperties = null;
    this.state.editVariant = false;
    this.state.variants = [];
    this.state.variantToggle = false;
    this.state.selectedStore = getDefaultStore().storeId;
    this.state.allStoresLoaded = false;
    this.state.cancelEditVariantName = "";
    this.state.apiError = null;
    this.state.showErrorDialog = false;
    this.state.status = isExtensionEnabled("MultiStoreSupport");
    this.state.taxes = [];
    this.state.isRecurringProduct = false;
    this.state.values.purchaseFrequencyData = {
      type: "Day",
      frequency: 0,
    };
    this.addVariant = this.addVariant.bind(this);
    this.removeVariant = this.removeVariant.bind(this);
    this.showProductDeleteConfirmation =
      this.showProductDeleteConfirmation.bind(this);
    this.hideProductDeleteConfirmation =
      this.hideProductDeleteConfirmation.bind(this);
    this.showVariantDeleteConfirmation =
      this.showVariantDeleteConfirmation.bind(this);
    this.hideVariantDeleteConfirmation =
      this.hideVariantDeleteConfirmation.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.getStores = this.getStores.bind(this);
    this.getEntityMetaData = this.getEntityMetaData.bind(this);
    this.getInventoryData = this.getInventoryData.bind(this);
    this.onSelectStore = this.onSelectStore.bind(this);
    this.hideApiErrorDialog = this.hideApiErrorDialog.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.toggleIsRecurringProduct = this.toggleIsRecurringProduct.bind(this);
    this.showAllVariantsDeleteConfirmation =
      this.showAllVariantsDeleteConfirmation.bind(this);
    this.hideAllVariantsDeleteConfirmation =
      this.hideAllVariantsDeleteConfirmation.bind(this);
    this.removeAllVariant = this.removeAllVariant.bind(this);
    this.status = {
      "Save as Draft": "DRAFT",
      "Submit for review": "PENDING",
    };
    this.isMarketPlaceSeller = isMarketPlaceSeller();
    this.isInputEditable = isSellerSection();
    this.isMarketPlaceOwner = isMarketPlaceOwner();
    this.isSellerSection = isSellerSection();
    let urlParams = new URL(document.location).searchParams;
    let sellerId = parseInt(urlParams.get("sellerId"));
    this.isOwnerCreatingRequest =
      this.isSellerSection &&
      this.isMarketPlaceOwner &&
      this.props.method === "add" &&
      Boolean(sellerId);
    this.isProductSubscriptionEnabled = isExtensionEnabled(
      "SubscriptionProducts"
    );
  }
  addVariant() {
    if (!this.state.variantName) {
      return;
    }

    const { productVariantProperties, variantFields } = this.state;
    const variantProperties = (
      productVariantProperties?.productProperties || []
    ).map((data, index) => {
      return {
        propertyId: data.id,
        value: variantFields[index],
      };
    });

    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      if (Array.isArray(newState.values.variants)) {
        if (newState.values.variants?.length) {
          if (newState.values.variants.length === 1) {
            if (!newState.values.variants[0].name) {
              newState.values.variants[0].name = this.state.variantName;
              if (!isEmpty(variantProperties)) {
                newState.values.variants[0].properties = variantProperties;
              }
              newState.variantName = "";
              newState.variantFields = [];
            } else {
              newState.values.variants.push({
                name: this.state.variantName,
                ...(variantProperties.length > 0 && {
                  properties: variantProperties,
                }),
              });
              newState.variantName = "";
              newState.variantFields = [];
            }
          } else {
            newState.values.variants.push({
              name: this.state.variantName,
              ...(variantProperties?.length > 0 && {
                properties: variantProperties,
              }),
            });
            newState.variantName = "";
            newState.variantFields = [];
          }
        }
      } else {
        newState.values.variants = [
          {
            name: this.state.variantName,
            ...(variantProperties?.length > 0 && {
              properties: variantProperties,
            }),
          },
        ];
        newState.variantName = "";
        newState.variantFields = [];
      }
      return newState;
    });
  }
  removeVariant(index) {
    this.setState((prevState) => {
      let newState = Object.assign({}, prevState);
      newState.values.variants.splice(index, 1);
      newState.validations.variants.splice(index, 1);
      if (newState.values.variants?.length === 1) {
        // Remove validation of 'variant name' field as it is no longer shown
        delete newState.validations.variants[0].name;
      } else if (!newState.values.variants?.length) {
        newState.values.variants.push({});
      }
      newState.selectedVariant = Math.max(prevState.selectedVariant - 1, 0);
      return newState;
    }, this.hideVariantDeleteConfirmation);
  }

  onToggleChange() {
    if (
      this.state.values &&
      this.state.values.variants &&
      this.state.values.variants.length > 1
    ) {
      this.setState({ deleteAllVariantsDialogShown: true });
    } else {
      this.setState({
        variantToggle: !this.state.variantToggle,
      });
    }
  }
  showAllVariantsDeleteConfirmation() {
    this.setState({
      deleteAllVariantsDialogShown: true,
    });
  }
  hideAllVariantsDeleteConfirmation() {
    this.setState({
      deleteAllVariantsDialogShown: false,
    });
  }
  removeAllVariant() {
    this.setState(
      (prevState) => {
        let newState = Object.assign({}, prevState);
        newState.values.variants.splice(1);
        newState.validations.variants.splice(1);
        if (this.props.method === "add") {
          if (
            newState.values.variants &&
            newState.values.variants.length === 1
          ) {
            delete newState.values.variants[0].name;
          }
        } else {
          if (
            newState.values.variants &&
            newState.values.variants.length === 1
          ) {
            if (!newState.values.variants[0].productId) {
              delete newState.values.variants[0].name;
            } else {
              delete newState.values.variants[0].name;
              delete newState.values.variants[0].fullName;
              delete newState.values.variants[0].id;
              delete newState.values.variants[0].productId;
              delete newState.values.variants[0].status;
            }
          }
        }
        return newState;
      },
      () => {
        this.setState({ variantToggle: false, selectedVariant: 0 });
        this.hideAllVariantsDeleteConfirmation();
      }
    );
  }
  showProductDeleteConfirmation(e) {
    e && e.preventDefault();
    this.setState({
      deleteProductDialogShown: true,
    });
  }
  hideProductDeleteConfirmation() {
    this.setState({
      deleteProductDialogShown: false,
    });
  }
  showVariantDeleteConfirmation() {
    this.setState({
      deleteVariantDialogShown: true,
    });
  }
  hideVariantDeleteConfirmation() {
    this.setState({
      deleteVariantDialogShown: false,
    });
  }
  hideApiErrorDialog() {
    this.setState({
      showErrorDialog: false,
    });
  }
  deleteProduct() {
    this.props.onDelete && this.props.onDelete();
  }
  beforeSubmit() {
    // Since variants are tabbed, when errors are there, navigate to the first tab with errors
    let index = -1;
    if (
      this.state.validations &&
      this.state.validations.variants &&
      this.state.validations.variants.length
    ) {
      index = this.state.validations.variants.findIndex(
        (variant) => !this.isFormValid(variant)
      );
    }
    if (index > -1) {
      this.setState({ selectedVariant: index });
    }
  }

  changeParamsDataType(param) {
    let params = Object.assign({}, param);
    if (params.discount !== undefined) {
      params.discount = parseFloat(params.discount);
    }
    if (params.mrp !== undefined) {
      params.mrp = parseFloat(params.mrp);
    }
    if (params.stock !== undefined) {
      params.stock = Number(params.stock);
    }
    if (!params.stock) {
      params.stock = 0;
    }
    if (
      params.unlimitedStock !== undefined &&
      params.unlimitedStock &&
      isExtensionEnabled("StockManagement")
    ) {
      params.stock = 0;
    }
    if (params.status === undefined) {
      params.status = isExtensionEnabled("MultiStoreSupport")
        ? "HIDDEN"
        : "ENABLED";
    }
    return params;
  }

  toggleIsRecurringProduct() {
    this.setState((prev) => ({
      isRecurringProduct: !prev.isRecurringProduct,
    }));
  }

  transformSubmit(formData) {
    const brandsEnabled = isExtensionEnabled("MultiBrandSupport");
    const entityMetaDataEnabled = isExtensionEnabled("EntityMetaData");
    let marginMetadata = null;
    // return null
    let modifiedData = Object.assign({}, formData);
    modifiedData.description = exportText(
      importText(this.state.values.description)
    );
    if (formData.categories) {
      modifiedData.primaryCategoryId = formData.categories[0].id;
      modifiedData.categoryIds = formData.categories.map(
        (category) => category.id
      );
    }
    delete modifiedData.primaryCategory;
    delete modifiedData.secondaryCategories;
    delete modifiedData.categories;

    if (
      !(
        isExtensionEnabled("MultiVariantSupport") && this.state.variantToggle
      ) &&
      modifiedData.variants &&
      modifiedData.variants.length > 0
    ) {
      if (isExtensionEnabled("MarketPlace")) {
        marginMetadata =
          modifiedData.variants?.[0]?.metaData["Minimum Profit Margin"];
      }
      delete modifiedData.variants[0].name;
      delete modifiedData.variants[0].fullName;
      delete modifiedData.variants[0].metaData;
      Object.assign(modifiedData, modifiedData.variants[0]);
      // delete modifiedData.variants
      modifiedData.variants = [];
      if (modifiedData.hasOwnProperty("hasVariants")) {
        modifiedData.hasVariants = 0;
      }
    }

    if (brandsEnabled && modifiedData.brand) {
      if (modifiedData.brand.name === modifiedData.brandName) {
        modifiedData.brandId = modifiedData.brand.id;
        delete modifiedData.brandName;
      }
      modifiedData.brandId = modifiedData.brand.id;
    }
    if (brandsEnabled && !modifiedData.brand) {
      modifiedData.brandId = "";
    }
    delete modifiedData.brand;
    if (modifiedData.tags) {
      modifiedData.tagIds = formData.tags.map((tag) => tag.id);
      delete modifiedData.tags;
    }
    if (modifiedData.metaData) {
      if (!entityMetaDataEnabled) {
        delete modifiedData.metaData;
      } else {
        let metaData = Object.assign({}, modifiedData.metaData);
        if (Object.keys(metaData)[0] === "0") {
          metaData = metaData["0"];
        }
        Object.keys(metaData).forEach((key) => {
          if (metaData[key] === "") {
            delete metaData[key];
          }
        });
        if (Object.keys(metaData).length) {
          modifiedData.metaData = metaData;

          if (isExtensionEnabled("MarketPlace")) {
            modifiedData.metaData["Minimum Profit Margin"] = marginMetadata;
          }
        } else {
          delete modifiedData.metaData;
        }
      }
    }

    if (modifiedData?.variants?.length) {
      (modifiedData?.variants || []).forEach((variant) => {
        if (!entityMetaDataEnabled) {
          delete variant?.metaData;
        }
      });
    }
    modifiedData.soldByWeight = modifiedData.soldByWeight ? 1 : 0;
    modifiedData.handlingDays = modifiedData.handlingDays || 0;
    modifiedData.bulkOrderThreshold = modifiedData.bulkOrderThreshold || 0;
    delete modifiedData.madeUp;
    return modifiedData;
  }

  pendingActionAddProduct = (id) => {
    const api = new API({ url: `/account-service/pending-action/${id}` });
    api
      .put({ status: "COMPLETED" })
      .then((response) => {
        unset("AddProductPendingAction");
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  onSubmitOwnerRequest(values, storeData, statusKey) {
    const statusMap = {
      Approve: "APPROVED",
      Reject: "REJECTED",
    };
    let urlParams = new URL(document.location).searchParams;
    let requestId = parseInt(urlParams.get("requestId"));
    const putApi = new API({
      url: `/catalogue-service/seller-product-request/${requestId}/approval`,
    });
    const { id, variants } = values;
    if (variants && variants.length > 1) {
      // to be removed in future after correcting DB design
      this.setState({
        submitting: false,
      });
      return;
    }
    const storeId = Object.keys(storeData[0]?.product)[0];
    const { sellingPrice, sellerDiscount } = storeData[0]?.product[storeId];

    const params = {
      organizationId: getSession()?.organization?.id?.toString(),
      storeId: Number(storeId),
      status: statusMap[statusKey],
      description: exportText(importText(this.state.values.description)),
      shortDescription: values.metaData?.["Short Description"],
      discount: sellerDiscount,
      sellingPrice,
      images: values.variants[0]?.images || [],
      productId: id,
      rejectionReason: this.state.rejectionReason,
    };

    this.submitApprovalStatus = (newParams) => {
      putApi
        .put({ ...params, ...newParams })
        .then((response) => {
          this.props.onCancel();
        })
        .catch((error) => {
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            apiError: error.message,
            showErrorDialog: true,
          });
          console.error(error);
        })
        .finally(() => {
          this.setState({ providersLoading: false, submitting: false });
        });
    };

    if (statusMap[statusKey] === "REJECTED" && !this.state.rejectionReason) {
      this.state.showRejectReasonForm = true;
      return;
    }

    this.submitApprovalStatus();
  }

  onSubmitRequest = (values, storeData, statusKey) => {
    const { isMinimumProfitMargin } = this.state;
    const { id, variants } = values;

    const requestId = new URLSearchParams(window.location.search).get(
      "requestId"
    );

    const isAddProductPage = window.location.pathname
      .split("/")
      .includes("add-product");
    const isApproved = this.state?.currentRequestStatus === "APPROVED";

    const apiUrl =
      isApproved || isAddProductPage
        ? `/catalogue-service/seller-product-request`
        : `/catalogue-service/seller-product-request/${requestId}`;

    const apiMethod = isApproved || isAddProductPage ? "post" : "put";

    const api = new API({ url: apiUrl });

    if (
      isMinimumProfitMargin ||
      (variants && variants.length > 1) ||
      (apiMethod === "put" && !requestId)
    ) {
      // Added for WSI, future after correcting DB design
      this.setState({
        submitting: false,
      });
      return;
    }

    const storeId = Object.keys(storeData[0]?.product)[0];
    const { sellingPrice, sellerDiscount } = storeData[0]?.product[storeId];
    const params = {
      organizationId: getSession()?.organization?.id?.toString(),
      storeId: Number(storeId),
      status: this.status[statusKey],
      description: exportText(importText(this.state.values.description)),
      shortDescription: values.metaData?.["Short Description"],
      discount: sellerDiscount,
      sellingPrice,
      images: values.variants[0]?.images || [],
      productId: id,
    };
    if (this.isOwnerCreatingRequest) {
      let urlParams = new URL(document.location).searchParams;
      let sellerId = parseInt(urlParams.get("sellerId"));
      params.sellerID = sellerId + "";
      params.status = "PENDING";
    }
    api[apiMethod](params)
      .then(() => {
        const message =
          params.status === "PENDING"
            ? "product.review.submission.message"
            : "product.draft.submission.message";
        this.setState({
          showReviewSubmissionDialog: true,
          requestSubmissionMessage: message,
        });
      })
      .catch((error) => {
        if (error.code === 401 || error.code === 403) throw error;
        this.setState({
          apiError: error.message,
          showErrorDialog: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  };

  async onSubmit(values, storeData) {
    // this.props.onSubmit(values)
    const putApi = new API({ url: `/catalogue-service/product/${values.id}` });
    const postApi = new API({ url: `/catalogue-service/product` });
    const inventoryApi = new API({ url: `/inventory-service/item` });

    const param = this.transformSubmit(values);
    const hasBrands = this.props.options && this.props.options.brands;
    const hasTags = this.props.options && this.props.options.tags;
    function updateInventoryAlert(productId, threshold) {
      const inventoryPutApi = new API({
        url: `/inventory-service/item/${productId}/alert`,
      });
      return inventoryPutApi.put({ threshold }).catch((error) => {
        console.error(error);
      });
    }
    if (this.props.method === "add") {
      if (this.props.options?.masterCatelogue) {
        if (!hasTags) {
          delete param.tagIds;
        }
        if (!hasBrands) {
          param.brandId = "";
        }
        if (param.variants && param.variants.length > 0) {
          param.variants.forEach((variant) => {
            delete variant.id;
          });
        }
        param.clientItemId = String(param.id);
        delete param.id;
        delete param.createdAt;
        delete param.productId;
      }
      postApi
        .post(param)
        .then(async (response) => {
          if (get("AddProductPendingAction")) {
            this.pendingActionAddProduct("PRODUCT_ADDITION");
          }

          if (storeData.length === 1 && storeData[0].product) {
            let data = storeData[0].product;
            await Promise.all(
              Object.keys(data).map(async (storeId) => {
                let params = data[storeId];
                params = this.changeParamsDataType(params);
                if (
                  (params.mrp !== 0 && !params.mrp) ||
                  (params.stock !== 0 && !params.stock)
                ) {
                  console.warn("Invalid params", params);
                  return;
                }
                params.productId = response.data?.product?.id;
                params.storeId = Number(storeId);
                if (isExtensionEnabled("MarketPlace")) {
                  const dealerPrice = params?.dealerPrice;
                  const mrp = params?.mrp;
                  const discount = mrp - dealerPrice;
                  params.discount = discount;
                  delete params?.dealerPrice;
                }
                try {
                  await inventoryApi.post(params);
                } catch (error) {
                  console.error(error);
                  if (error.code === 401 || error.code === 403) {
                    throw error;
                  }
                  this.setState({
                    apiError: error.message,
                    showErrorDialog: true,
                  });
                }
              })
            );
            if (isMarketPlaceOwner()) {
              const { variants } = response.data?.product;
              const productId = response.data?.product?.id;
              this.handlePermissableSellerSubmit(
                values.permissableSellers,
                productId
              );

              if (!variants || (variants && variants?.length === 0)) {
                await updateInventoryAlert(productId, Number(param?.threshold));
              } else {
                const updatePromises = variants.map((variant) => {
                  const matchingVariant = param.variants.find(
                    (v) => v.id === variant.id
                  );
                  return updateInventoryAlert(
                    variant.id,
                    Number(matchingVariant?.threshold)
                  );
                });
                await Promise.all(updatePromises);
              }
            }
          } else {
            await Promise.all(
              storeData.map(async (variant) => {
                await Promise.all(
                  Object.keys(variant).map(async (variantName) => {
                    if (!variantName) return;

                    if (variant[variantName]) {
                      await Promise.all(
                        Object.keys(variant[variantName]).map(
                          async (storeId) => {
                            let params = variant[variantName][storeId];
                            const foundVariant =
                              response.data?.product?.variants?.find(
                                (v) => v.name === variantName
                              );
                            params.productId = foundVariant
                              ? foundVariant.id
                              : null;
                            params.storeId = Number(storeId);
                            params = this.changeParamsDataType(params);
                            if (isExtensionEnabled("MarketPlace")) {
                              const dealerPrice = params?.dealerPrice;
                              const mrp = params?.mrp;
                              const discount = mrp - dealerPrice;
                              params.discount = discount;
                              delete params?.dealerPrice;
                            }
                            if (
                              (params.mrp !== 0 && !params.mrp) ||
                              (params.stock !== 0 && !params.stock)
                            ) {
                              console.warn("Invalid params", params);
                              return;
                            }
                            try {
                              await inventoryApi.post(params);
                            } catch (error) {
                              console.error(error);
                              if (error.code === 401 || error.code === 403) {
                                throw error;
                              }
                              this.setState({
                                apiError: error.message,
                                showErrorDialog: true,
                              });
                            }
                          }
                        )
                      );
                    }
                  })
                );
              })
            );
            if (isMarketPlaceOwner()) {
              const { variants } = response.data?.product;
              const productId = response.data?.product?.id;
              this.handlePermissableSellerSubmit(
                values.permissableSellers,
                productId
              );

              if (variants || (variants && variants?.length === 0)) {
                await updateInventoryAlert(productId, Number(param?.threshold));
              } else {
                const updatePromises = variants.map((variant) => {
                  const matchingVariant = param.variants.find(
                    (v) => v.id === variant.id
                  );
                  return updateInventoryAlert(
                    variant.id,
                    Number(matchingVariant?.threshold)
                  );
                });
                await Promise.all(updatePromises);
              }
            }
          }

          if (this.isProductSubscriptionEnabled) {
            const productId = response?.data?.product?.id;
            const productSubscriptionApi = new API({
              url: `/catalogue-service/product/${productId}/subscription`,
            });
            const formattedData = convertToISODuration(
              values.purchaseFrequencyData
            );
            const params = { frequency: formattedData };
            await productSubscriptionApi
              .put(params)
              .then(() => {})
              .catch((error) => {
                console.error(error);
              });
          }

          if (param.status === "PENDING") {
            this.setState({
              showReviewSubmissionDialog: true,
            });
          } else {
            this.props.onCancel();
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.code === 401 || error.code === 403) {
            throw error;
          }
          this.setState({
            apiError: error.message,
            showErrorDialog: true,
            submitting: false,
          });
        });
    } else {
      param.variants &&
        param.variants.forEach((variant) => {
          if (variant.id && !variant.productId) {
            delete variant.id;
          }
        });

      try {
        const response = await putApi.put(param);
        if (get("AddProductPendingAction")) {
          this.pendingActionAddProduct("PRODUCT_ADDITION");
        }
        await Promise.all(
          storeData.map(async (variant) => {
            await Promise.all(
              Object.keys(variant).map(async (variantName) => {
                if (variant[variantName]) {
                  await Promise.all(
                    Object.keys(variant[variantName]).map(async (storeId) => {
                      let params = variant[variantName][storeId];
                      if (params.productId) {
                        params.productId = variant.id;
                      } else if (
                        !response.data?.product?.variants ||
                        (response.data.product?.variants &&
                          response.data.product.variants?.length === 0)
                      ) {
                        params.productId = response.data?.product?.id;
                      } else {
                        const foundVariant =
                          response.data.product?.variants?.find(
                            (v) => v.name === variantName
                          );
                        params.productId = foundVariant
                          ? foundVariant.id
                          : null;
                      }

                      params.storeId = Number(storeId);
                      params = this.changeParamsDataType(params);
                      if (isExtensionEnabled("MarketPlace")) {
                        const dealerPrice = params?.dealerPrice;
                        const mrp = params?.mrp;
                        const discount = mrp - dealerPrice;
                        params.discount = discount;
                        delete params?.dealerPrice;
                      }
                      if (
                        (params.mrp !== 0 && !params.mrp) ||
                        (params.stock !== 0 && !params.stock)
                      ) {
                        console.error("Invalid params", params);
                        return;
                      }

                      if (!isExtensionEnabled("StockManagement")) {
                        params.unlimitedStock = false;
                      }

                      try {
                        await inventoryApi.post(params);
                      } catch (error) {
                        console.error(error);
                        if (error.code === 401 || error.code === 403) {
                          throw error;
                        }
                        this.setState({
                          apiError: error.message,
                          showErrorDialog: true,
                        });
                      }
                    })
                  );
                }
              })
            );
          })
        );

        if (isMarketPlaceOwner()) {
          const { variants } = response.data?.product;
          const productId = response.data?.product?.id;
          this.handlePermissableSellerSubmit(
            values.permissableSellers,
            productId
          );

          if (!variants || (variants && variants?.length === 0)) {
            await updateInventoryAlert(productId, Number(param?.threshold));
          } else {
            const updatePromises = variants.map((variant) => {
              const matchingVariant = param.variants.find(
                (v) => v.id === variant.id
              );
              return updateInventoryAlert(
                variant.id,
                Number(matchingVariant?.threshold)
              );
            });
            await Promise.all(updatePromises);
          }
        }

        this.setState({ submitting: false });

        if (this.isProductSubscriptionEnabled) {
          const { id, variants = [] } = values;
          const productId = variants.length > 0 ? variants[0]?.id : id;
          if (productId) {
            const productSubscriptionApi = new API({
              url: `/catalogue-service/product/${productId}/subscription`,
            });
            const formattedData = convertToISODuration(
              values.purchaseFrequencyData
            );
            const params = { frequency: formattedData };
            await productSubscriptionApi
              .put(params)
              .then(() => {})
              .catch((error) => {
                console.error(error);
              });
          }
        }

        if (param.status === "PENDING") {
          this.setState({
            showReviewSubmissionDialog: true,
          });
        } else {
          this.props.onCancel();
        }
      } catch (error) {
        console.error(error);
        if (error.code === 401 || error.code === 403) {
          throw error;
        }
        this.setState({
          apiError: error.message,
          showErrorDialog: true,
          submitting: false,
        });
      }
    }
  }

  handlePermissableSellerSubmit = (permissableSellers = null, id) => {
    if (!permissableSellers) return;

    const addedSellers = this.state?.permissableSellers || [];
    const permissablePayload = permissableSellers.map((seller) => ({
      sellerID: seller.id,
      status: "ENABLED",
    }));

    const existingSellerIDs = new Set(
      permissablePayload.map((item) => item.sellerID)
    );

    addedSellers.forEach((seller) => {
      if (!existingSellerIDs.has(seller.id)) {
        permissablePayload.push({ sellerID: seller.id, status: "DISABLED" });
      }
    });

    const permissableApi = new API({
      url: `/catalogue-service/product/${id}/permissible-seller`,
    });

    permissableApi
      .put({ permissibleProductSeller: permissablePayload })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  _submitHandler(e) {
    e && e.preventDefault();
    this.beforeSubmit();
    this.setState({
      pressedSubmitWithCurrentData: true,
      submitting: true,
    });
    let isValid = this.isFormValid();
    let storeData = [];

    if (isValid) {
      const values = JSON.parse(JSON.stringify(this.state.values));
      values.variants &&
        values.variants.forEach((variant) => {
          if (variant.name) {
            storeData.push({ [variant.name]: variant.storeSpecificData });
          }
          if (!variant.name) {
            storeData.push({ product: variant.storeSpecificData });
          }
          delete variant.storeSpecificData;
        });

      // submission function
      if (this.isMarketPlaceSeller || this.isOwnerCreatingRequest) {
        this.onSubmitRequest(
          values,
          storeData,
          e?.nativeEvent?.submitter?.value
        );
      } else if (this.isMarketPlaceOwner && this.isSellerSection()) {
        this.onSubmitOwnerRequest(
          values,
          storeData,
          e?.nativeEvent?.submitter?.value
        );
      } else {
        this.onSubmit(values, storeData);
      }
    } else {
      this.setState({ submitting: false });
    }
  }
  getStores() {
    if (this.props.options && this.props.options.multipleStores) {
      this.setState({
        loadingStores: true,
        failedLoadingStores: false,
      });
      this.props.options
        .getStores()
        .then((stores) => {
          this.setState(
            (prevState) => {
              let newState = Object.assign({}, prevState, {
                stores,
                loadingStores: false,
                failedLoadingStores: false,
              });
              return newState;
            },
            () => {
              // const location = window.location.pathname.split("/");
              if (window.location.pathname.split("/").includes("add")) {
                if (this.props.options?.masterCatelogue && this.state.stores) {
                  const defaultStore = this.state.stores.filter(
                    (store) => store.default === true
                  );
                  this.getInventoryData(defaultStore[0]?.id);
                }
                return null;
              }
              (this.state.stores || [{ id: getDefaultStore().storeId }]).map(
                (store) =>
                  (store.hasPicking || store.hasSelfCheckout) &&
                  this.getInventoryData(store.id)
              );
            }
          );
        })
        .catch((error) => {
          this.setState({
            loadingStores: false,
            failedLoadingStores: true,
          });
          console.error(error);
        });
    } else {
      this.setState(
        (prevState) => {
          let newState = Object.assign({}, prevState, {
            stores: getStores(),
          });
          return newState;
        },
        () => {
          if (this.props.method === "add") {
            if (this.props.options?.masterCatelogue && this.state.stores) {
              const defaultStore = this.state.stores.filter(
                (store) => store.default === true
              );
              this.getInventoryData(defaultStore[0]?.id);
            }
            return null;
          }
          this.getInventoryData(get("store"));
        }
      );
    }
  }

  onSelectStore(storeId) {
    this.setState({ selectedStore: storeId });
  }

  handleUpdateMetaData = (metaValue, metaResponse) => {
    let metaData = metaValue;
    if (!metaData) {
      metaData = {};
      metaResponse &&
        Object.keys(metaResponse).forEach((mt) => {
          metaData[mt] = "";
        });
      metaValue = {
        ...metaData,
      };
    } else {
      let newMetaData = {};
      metaResponse &&
        Object.keys(metaResponse).forEach((mt) => {
          newMetaData[mt] = metaData[mt] || "";
        });
      metaValue = {
        ...metaData,
      };
    }
    return metaValue;
  };

  getEntityMetaData() {
    let { props } = this;
    if (
      props.options &&
      props.options.entityMetaData &&
      props.options.getProductMetaData
    ) {
      this.setState({
        loadingProductMetaData: true,
      });
      props.options.getProductMetaData().then((metaDataResponse) => {
        const productMetaData = metaDataResponse.product;
        const variantMetaData = metaDataResponse?.variant;
        this.productMetaDataWithType = metaDataResponse;

        if (isExtensionEnabled("MarketPlace")) {
          this.marketPlaceVariantMetadata = {
            "Minimum Profit Margin":
              metaDataResponse.product?.["Minimum Profit Margin"],
          };
          this.marketPlaceProductMetadata = {
            "Short Description":
              metaDataResponse.product?.["Short Description"],
          };
          if (variantMetaData?.metaData)
            variantMetaData["Minimum Profit Margin"] =
              metaDataResponse.product["Minimum Profit Margin"];

          delete productMetaData?.["Minimum Profit Margin"];
          delete productMetaData?.["Short Description"];
        }

        let values = Object.assign({}, this.state.values);

        values.metaData = this.handleUpdateMetaData(
          values.metaData,
          productMetaData
        );

        if (values?.variants?.length) {
          values.variants.forEach((variant, index) => {
            variant.metaData = this.handleUpdateMetaData(
              variant?.metaData,
              variantMetaData
            );
          });
        }

        this.setState({
          values,
          loadingProductMetaData: false,
        });
      });
    }
  }

  getInventoryData(storeId) {
    const variants = Object.assign({}, this.state.values.variants);
    let ids = [];
    Object.values(variants).forEach((variant) => {
      ids.push(variant.id);
    });
    this.props.options.getInventoryData &&
      this.props.options.getInventoryData(storeId, ids).then(
        (response) => {
          // let variants = this.state.values && this.state.values.variants && Object.assign({}, this.state.values.variants)
          let newValues = this.state.values && cloneDeep(this.state.values);
          let variants = newValues.variants;
          variants &&
            variants?.length === response?.data?.length &&
            variants.forEach((variant, index) => {
              variant.storeSpecificData = variant.storeSpecificData || {};
              variant.storeSpecificData[storeId] = response.data?.[index] || {
                location: {},
                tax: {},
              };
              if (isExtensionEnabled("MarketPlace")) {
                const mrp = variant.storeSpecificData[storeId]?.mrp;
                const discount = variant.storeSpecificData[storeId]?.discount;
                const dealerPrice = mrp - discount;
                variant.storeSpecificData[storeId].dealerPrice = dealerPrice;
              }
            });
          this.setState({ values: newValues });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  updateItemData = (item) => {
    this.props.options
      .updateItem(item.url, item.name)
      .then((response) => {
        const dataItem = response?.data?.[item.keyName];

        if (item.keyName === "brand") {
          this.setState((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.values.brand = dataItem;
            return newState;
          });
        } else {
          this.setState((prevState) => {
            let newState = Object.assign({}, prevState);
            newState.values[item.stateKeyName] = [dataItem];
            return newState;
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  handleVariantName = () => {
    const { variantFields } = this.state;
    const filteredName = variantFields.filter((str) => str !== undefined);
    const concatinatedName = filteredName.join("-");
    this.setState({
      variantName: concatinatedName,
    });
  };

  handleCancelEditVariant = () => {
    this.setState(
      (prevState) => {
        let newState = Object.assign({}, prevState);
        newState.values.variants = this.state.variants;
        newState.values.variants[this.state.selectedVariant].name =
          this.state.cancelEditVariantName;
        return newState;
      },
      () => {
        this.setState({
          editVariant: !this.state.editVariant,
        });
      }
    );
  };
  handleEditVariantName = () => {
    const {
      editVariantFields,
      selectedVariant,
      productVariantProperties,
      values,
    } = this.state;

    if (isEmpty(productVariantProperties?.productProperties)) {
      this.setState(
        (prevState) => {
          let newState = Object.assign({}, prevState);
          newState.values.variants = this.state.variants;
          return newState; // should return newState instead of prevState
        },
        () => {
          this.setState({
            editVariant: !this.state.editVariant,
          });
        }
      );
    } else {
      const filteredName = editVariantFields.filter((str) => str !== undefined);
      const concatinatedName = filteredName.join("-");

      const variantProperties = (
        productVariantProperties?.productProperties || []
      ).map((data, index) => {
        return {
          id: data.variantId,
          propertyId: data.id,
          value: editVariantFields[index],
        };
      });

      let valueCopy = cloneDeep(values);
      valueCopy.variants[selectedVariant].name = concatinatedName;
      valueCopy.variants[selectedVariant].properties = variantProperties;

      this.setState({
        values: valueCopy,
        editVariant: !this.state.editVariant, // removed second call to setState
        editVariantFields: [],
      });
    }
  };

  handleVariantFieldChange = (value, index) => {
    this.setState((prevState) => {
      const variantFields = [...prevState.variantFields];
      variantFields[index] = value;
      return { variantFields };
    }, this.handleVariantName);
  };

  handleEditVariantFieldChange = (value, index) => {
    if (this.state.editVariantFields) {
      this.setState((prevState) => {
        const editVariantFields = [...prevState.editVariantFields];
        editVariantFields[index] = value;
        return { editVariantFields };
      }, this.handleVariantName);
    }
  };

  getItemData = () => {
    const brandName = this.props.value?.brand?.name;
    const categoryName = this.props.value?.categories?.[0]?.name;
    const tagName = this.props.value?.tags?.[0]?.name;
    const hasBrands = this.props.options && this.props.options.brands;
    const hasTags = this.props.options && this.props.options.tags;

    const itemList = [
      {
        url: "/catalogue-service/category",
        name: categoryName,
        keyName: "category",
        stateKeyName: "categories",
        enable: true,
      },
      {
        url: "/catalogue-service/tag",
        name: tagName,
        keyName: "tag",
        stateKeyName: "tags",
        enable: hasTags,
      },
      {
        url: "/catalogue-service/brand",
        name: brandName,
        keyName: "brand",
        stateKeyName: "brand",
        enable: hasBrands,
      },
    ];
    itemList
      .filter((item) => item.enable)
      .forEach((item) => {
        item.name &&
          this.props.options
            .getItem(item.url, item.name)
            .then((response) => {
              const dataList = response?.data?.[item.keyName]?.filter(
                (data) => data.name === item.name
              );

              if (dataList.length) {
                if (item.keyName === "brand") {
                  this.setState((prevState) => {
                    let newState = Object.assign({}, prevState);
                    newState.values.brand = dataList[0];
                    return newState;
                  });
                } else {
                  this.setState((prevState) => {
                    let newState = Object.assign({}, prevState);
                    newState.values[item.stateKeyName] = dataList;
                    return newState;
                  });
                }
              } else {
                this.updateItemData(item);
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
      });
  };

  fetchThreshold = async (id) => {
    try {
      const response = await this.getInventoryAlert(id).get();
      return response?.data?.threshold;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  getInventoryAlert = (productId) => {
    const inventoryPutApi = new API({
      url: `/inventory-service/item/${productId}/alert`,
    });
    return inventoryPutApi;
  };

  fetchAllThresholds = async (valueFromProps) => {
    const productIds =
      valueFromProps.variants.length === 0
        ? [valueFromProps.id]
        : valueFromProps.variants.map((variant) => variant.id);

    try {
      const thresholds = await Promise.all(
        productIds.map(async (id) => await this.fetchThreshold(id))
      );

      const thresholdsByVariantId = {};

      productIds.forEach((id, index) => {
        thresholdsByVariantId[id] = thresholds?.[index];
      });

      // Update thresholds inside the variants array
      const updatedVariants = valueFromProps.variants.map((variant) => ({
        ...variant,
        threshold: thresholdsByVariantId[variant.id],
      }));

      this.setState((prevState) => ({
        values: {
          ...prevState.values,
          variants: updatedVariants,
        },
      }));
    } catch (error) {
      console.error("Error fetching thresholds:", error);
    }
  };

  getRefactorPermissableSellers = (response = []) => {
    let permissibleSellers = [];
    permissibleSellers = response?.map((seller) => {
      return {
        id: seller.sellerId,
        name: seller.name,
      };
    });
    return permissibleSellers;
  };

  getPermissableSellers = () => {
    const productId = this.state.values.id;
    if (productId) {
      const permissableApi = new API({
        url: `/catalogue-service/permissible-seller?productId=${productId}&status=ENABLED`,
      });
      permissableApi
        .get()
        .then((response) => {
          let values = Object.assign({}, this.state.values);
          const permissableSellers = this.getRefactorPermissableSellers(
            response?.data?.permissibleSellers
          );
          values.permissableSellers = permissableSellers;
          this.setState({
            values,
            permissableSellers,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  fetchSellerRequestData = () => {
    const requestId = new URLSearchParams(window.location.search).get(
      "requestId"
    );

    if (!requestId) return;

    const api = new API({
      url: `/catalogue-service/seller-product-request/${parseInt(requestId)}`,
    });

    api
      .get()
      .then(({ data: { request } }) => {
        const {
          images: imagesArr,
          description,
          shortDescription,
          sellingPrice,
          discount,
          status,
        } = request;

        if (this.isSellerSection()) {
          this.setState(
            (prevState) => {
              const updatedVariants = prevState.values.variants.map(
                (item, index) => {
                  if (index !== 0) return item;

                  const storeKey = Object.keys(item.storeSpecificData)[0];
                  const storeData = item.storeSpecificData[storeKey];

                  return {
                    ...item,
                    images: imagesArr ?? item.images,
                    storeSpecificData: {
                      ...item.storeSpecificData,
                      [storeKey]: {
                        ...storeData,
                        sellingPrice: sellingPrice,
                        sellerDiscount: discount,
                      },
                    },
                  };
                }
              );

              return {
                currentRequestStatus: status,
                ...prevState,
                values: {
                  ...prevState.values,
                  sellerName: request?.sellerName,
                  description: description ?? prevState.values.description,
                  variants: updatedVariants,
                  metaData: {
                    ...prevState.values.metaData,
                    "Short Description":
                      shortDescription ??
                      prevState?.values?.metaData?.["Short Description"],
                  },
                },
              };
            },
            () => {
              const storeKey = Object.keys(
                this.state.values.variants[0].storeSpecificData
              )[0];
              this.handleSellingAndProfitMargin(0, storeKey);
            }
          );
        }
      })
      .catch(console.error);
  };

  fetchSubscriptionData = () => {
    const { id, variants = [] } = this.state.values;
    const productId = variants.length > 0 ? variants[0]?.id : id;

    const productSubscriptionApi = new API({
      url: `/catalogue-service/product/${productId}/subscription`,
    });

    productSubscriptionApi
      .get()
      .then((resp) => {
        const formattedTime = parseISODuration(
          resp.data.productSubscription?.frequency
        );
        const updatedValues = {
          ...this.state.values,
          purchaseFrequencyData: formattedTime,
        };

        this.setState({
          isRecurringProduct: true,
          values: updatedValues,
        });
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error);
      });
  };

  async componentDidMount() {
    const location = window.location.pathname.split("/");
    let urlParams = new URL(document.location).searchParams;
    let requestId = parseInt(urlParams.get("requestId"));
    if (isMarketPlaceOwner() && !isSellerSection()) {
      this.getPermissableSellers();
    }
    const valueFromProps = this.props.value;
    if (
      isExtensionEnabled("MarketPlace") &&
      (this.props.method === "edit" || location.includes("add-product")) &&
      valueFromProps
    ) {
      await this.fetchAllThresholds(valueFromProps);
    }
    let inventoryConfig = new API({ url: `/config-service/config/inventory` });
    inventoryConfig
      .get()
      .then((response) => {
        let taxArray = response.data?.inventory?.taxes || [];
        this.setState({ taxArray });
        if (response.data?.inventory?.taxes === null) {
          const country = new API({
            url: `/account-service/country?isoCode=${
              getSession().organization.countryCode
            }`,
          });
          country.get("", { "X-API-VERSION": 2 }).then((response) => {
            this.setState({ taxes: response.data?.country?.[0]?.tax });
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });

    if (isExtensionEnabled("MultiVariantSupport")) {
      const getProductProperties = new API({
        url: `/catalogue-service/product-properties`,
      });
      getProductProperties
        .get()
        .then((response) => {
          this.setState({
            productVariantProperties: response.data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (
      this.props.options?.masterCatelogue &&
      this.props.method === "add" &&
      this.props.value
    ) {
      // get the category, tag, brand for state.values
      // as masterCatelogue has different id these
      this.getItemData();
    }
    if (
      this.props.method === "edit" &&
      valueFromProps &&
      valueFromProps.variants
    ) {
      if (
        valueFromProps.variants.length === 1 &&
        valueFromProps.variants[0].name
      ) {
        this.setState({ variantToggle: true });
      }
      if (valueFromProps.variants.length > 1) {
        this.setState({ variantToggle: true });
      }
    }
    this.getStores();
    this.getEntityMetaData();
    let tabs = {
      description: getMessage("product.form.description.heading"),
      otherDetails: getMessage("product.form.other.details.heading"),
    };
    if (!isExtensionEnabled("EntityMetaData")) {
      delete tabs.otherDetails;
    }
    this.setState({ selected: tabs.description, tabs: tabs });
    const supportVariants =
      (this.props.options &&
        this.props.options.variants &&
        this.props.method === "add") ||
      (this.props.options &&
        this.props.options.variants &&
        this.props.value &&
        this.props.value.variants &&
        this.props.value.variants.length > 0);
    if (
      supportVariants &&
      this.props.method === "edit" &&
      this.getState(["variants"]).length > 1
    ) {
      this.setState({ variantToggle: true });
    }
    // this.getInventoryData(getDefaultStore().storeId)
    const storeId = get("store");
    if (storeId) {
      this.updateState(
        ["variants", 0, "storeSpecificData", storeId, "status"],
        "ENABLED"
      );
    }
    if (isSellerSection() && requestId) {
      this.fetchSellerRequestData();
    }
    if (this.isProductSubscriptionEnabled && this.props.method === "edit") {
      this.fetchSubscriptionData();
    }
  }

  handleEditBtnClick = () => {
    const { productVariantProperties, selectedVariant, values } = this.state;
    const oldVariant =
      this.state.values?.variants[this.state.selectedVariant]?.name;
    this.setState({
      cancelEditVariantName: oldVariant,
    });
    if (isEmpty(productVariantProperties?.productProperties)) {
      this.setState({
        editVariant: !this.state.editVariant,
        variants: this.state.values.variants,
      });
      return;
    }

    const { productProperties } = productVariantProperties;
    const selectedVariantObj = values.variants[selectedVariant];
    const { name } = selectedVariantObj;
    const variantNameArray = name?.split("-");

    const formattedName = variantNameArray?.filter(
      (ele, index) => index < productProperties.length
    );

    this.setState(
      {
        editVariantFields: formattedName,
      },
      this.setState({
        editVariant: !this.state.editVariant,
      })
    );
  };

  handleSellingPrice = (e, variantIndex, storeId, type) => {
    const value = e;
    this.setState(
      (prevState) => {
        const newState = { ...prevState };
        const newVariant = [...newState.values.variants];
        const storeSpecificData = {
          ...newVariant[variantIndex].storeSpecificData,
        };
        const storeData = { ...storeSpecificData[storeId] };
        storeData[type] = value;

        storeSpecificData[storeId] = storeData;
        newVariant[variantIndex].storeSpecificData = storeSpecificData;
        newState.values.variants = newVariant;
        return newState;
      },
      () => {
        this.handleSellingAndProfitMargin(variantIndex, storeId);
      }
    );
  };

  handleSellingAndProfitMargin = (variantIndex, storeId) => {
    const sellingPrice =
      this.state.values.variants[variantIndex].storeSpecificData[storeId]
        .sellingPrice || 0;
    const sellerDiscount =
      this.state.values.variants[variantIndex].storeSpecificData[storeId]
        .sellerDiscount || 0;
    const sellingMargin = sellingPrice - sellerDiscount;
    const mrp =
      this.state.values.variants[variantIndex].storeSpecificData[storeId].mrp ||
      0;
    const discount =
      this.state.values.variants[variantIndex].storeSpecificData[storeId]
        .discount || 0;
    const dealerPrice = mrp - discount;
    const profitMargin = ((sellingMargin - dealerPrice) / dealerPrice) * 100;
    const minimumProfitMargin =
      this.state.values.variants[variantIndex].metaData[
        "Minimum Profit Margin"
      ];
    this.setState({
      profitMargin: profitMargin.toFixed(2),
      isMinimumProfitMargin: Number(profitMargin) < Number(minimumProfitMargin),
      sellingMargin,
    });
  };

  render() {
    const { SubmitButton, CancelButton } = this.buttons;
    const { Form } = this.components;
    // Allow user to create more variants if the product already had variants
    const supportVariants =
      (this.props.options &&
        this.props.options.variants &&
        this.props.method === "add") ||
      (this.props.options &&
        this.props.options.variants &&
        this.props.value &&
        this.props.value.variants &&
        this.props.value.variants.length > 0);
    const booleanStock =
      this.props.options &&
      this.props.options.stockStrategy === STOCK_STRATEGIES.BOOLEAN;
    // const hasMultipleStores = this.props.options && this.props.options.multipleStores
    const hasTags = this.props.options && this.props.options.tags;
    const hasInstoreProcessing =
      this.props.options && this.props.options.inStoreProcessing;
    const hasSoldByWeight =
      this.props.options && this.props.options.soldByWeight;
    const preOrderSupport =
      this.props.options && this.props.options.preOrderSupport;
    const bulkOrderSuppport =
      this.props.options && this.props.options.bulkOrderSuppport;
    const namepattern = "[a-zA-Z0-9\\s]+([0-9]+\\s+(kg|g|gm))$";
    const variantpattern = "([a-zA-Z0-9]\\s*)*([0-9]+\\s+(kg|g|gm))$";
    const namePattern =
      this.state.values.soldByWeight && !this.state.values.variants
        ? namepattern
        : ".*";
    const variantPattern = this.state.values.soldByWeight
      ? variantpattern
      : ".*";
    const enabledSoldByWeight = this.state.values.soldByWeight;
    let stores = this.state.stores && this.state.stores.slice();
    stores =
      stores &&
      stores.filter((store) => store.hasPicking || store.hasSelfCheckout);
    stores =
      stores &&
      Object.assign({}, ...stores.map((item) => ({ [item["id"]]: item })));
    let { tabs, selected } = this.state;
    const checkEditStatus = (variantIndex) => {
      if (this.props?.value?.variants) {
        if (this.props?.value?.variants[variantIndex]?.clientItemId) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };
    const countryCode = getSession().organization.countryCode || "IN";
    const clientItemLabel = fetchClientItemLabel(countryCode);

    const checkLowStockEditStatus = (variantIndex) => {
      if (!isMarketPlaceOwner()) {
        if (this.props?.value?.variants) {
          if (this.props?.value?.variants[variantIndex]?.clientItemId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    };

    const getVariantInputFields = (variantIndex) => {
      const { productVariantProperties, variantFields } = this.state;
      if (isEmpty(productVariantProperties?.productProperties)) {
        return (
          <Input
            className="variant-name"
            label={getMessage("product.form.variant.name.heading")}
            placeholder={getMessage("product.form.variant.name.placeholder")}
            name={`variant-${variantIndex}-name`}
            type="text"
            required
            pattern={variantPattern}
            suffix={
              supportVariants ? (
                <button
                  type="button"
                  className="add-variant-link"
                  onClick={this.addVariant}
                >
                  <span>+</span>{" "}
                  {getMessage("product.form.variant.addButtonText")}
                </button>
              ) : null
            }
            value={this.state.variantName}
            readOnly={this.state.editVariant}
            onChange={(value) => this.setState({ variantName: value })}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            validationStrings={{
              valueMissing: getMessage("input.requiredMessage"),
            }}
          >
            {enabledSoldByWeight
              ? getMessage("product.form.variant.pattern")
              : ""}
          </Input>
        );
      }

      const variantFieldsLength = variantFields.reduce(
        (count, currentValue) => {
          if (Boolean(currentValue && currentValue.trim())) {
            return count + 1;
          }
          return count;
        },
        0
      );

      const { productProperties } = productVariantProperties;

      return (
        <>
          {productProperties.map((data, index) => (
            <Input
              key={index} // Add a key prop to the Input component to avoid a warning
              className="variant-name"
              placeholder={data?.name}
              name={`variant-${variantIndex}-name`}
              type="text"
              required
              pattern={variantPattern}
              suffix={null}
              value={this.state.variantFields[index]}
              readOnly={this.state.editVariant}
              onChange={(value) => this.handleVariantFieldChange(value, index)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              validationStrings={{
                valueMissing: getMessage("input.requiredMessage"),
              }}
            >
              {enabledSoldByWeight
                ? getMessage("product.form.variant.pattern")
                : ""}
            </Input>
          ))}
          <button
            type="button"
            className="add-variant-link"
            onClick={this.addVariant}
            disabled={variantFieldsLength !== productProperties.length}
          >
            <span>+</span> {getMessage("product.form.variant.addButtonText")}
          </button>
        </>
      );
    };

    this.isSellerSection = () => {
      const location = window.location.pathname.split("/");
      if (isExtensionEnabled("MarketPlace")) {
        if (isMarketPlaceSeller() || location.includes("seller-catalogue")) {
          return true;
        }
      }
      return false;
    };

    const getEditVariantInputs = () => {
      const { productVariantProperties, selectedVariant, editVariantFields } =
        this.state;
      if (isEmpty(productVariantProperties?.productProperties)) {
        return (
          <>
            <div>
              {Array.isArray(this.state.values.variants)
                ? this.state.values.variants.map((variant, index, arr) => {
                    return (
                      <Input
                        key={`variant-${index}`}
                        value={this.state.variants[index].name}
                        onChange={(value) => {
                          this.setState((prevState) => {
                            let newState = cloneDeep(prevState);
                            newState.variants[index].name = value;
                            return newState;
                          });
                        }}
                        readOnly={this.state.selectedVariant !== index}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                          }
                        }}
                      />
                    );
                  })
                : null}
            </div>
            <div>
              <button
                type="button"
                className="primary variant-save"
                // disabled={variantFieldsLength !== productProperties.length}
                onClick={() => {
                  this.handleEditVariantName();
                }}
              >
                {getMessage("save.text")}
              </button>

              <button
                type="button"
                className="button variant-cancel"
                onClick={() => {
                  this.handleCancelEditVariant();
                }}
              >
                {getMessage("cancel.text")}
              </button>
            </div>
          </>
        );
      }

      const variantFieldsLength = editVariantFields?.reduce(
        (count, currentValue) => {
          if (Boolean(currentValue && currentValue.trim())) {
            return count + 1;
          }
          return count;
        },
        0
      );

      const { productProperties } = productVariantProperties;
      return (
        <>
          <div>
            {productProperties?.map((data, index) => (
              <Input
                key={index} // Add a key prop to the Input component to avoid a warning
                className="variant-name"
                placeholder={data?.name}
                name={`variant-${selectedVariant}-name`}
                type="text"
                required
                pattern={variantPattern}
                suffix={null}
                value={
                  (this.state?.editVariantFields &&
                    this.state?.editVariantFields[index]) ||
                  null
                }
                onChange={(value) =>
                  this.handleEditVariantFieldChange(value, index)
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                }}
              >
                {enabledSoldByWeight
                  ? getMessage("product.form.variant.pattern")
                  : ""}
              </Input>
            ))}
          </div>

          <div>
            <button
              type="button"
              className="primary variant-save"
              disabled={variantFieldsLength !== productProperties.length}
              onClick={() => {
                this.handleEditVariantName();
              }}
            >
              {getMessage("save.text")}
            </button>

            <button
              type="button"
              className="button variant-cancel"
              onClick={() => {
                this.setState({
                  variants: this.getState(["variants"]),
                  editVariant: !this.state.editVariant,
                });
              }}
            >
              {getMessage("cancel.text")}
            </button>
          </div>
        </>
      );
    };
    const currentTab = getProductRequestStatus()[get(`productPage-tabIndex`)];
    const showApproveBtn =
      this.state?.currentRequestStatus !== "APPROVED" &&
      !this.isOwnerCreatingRequest;

    const showRejectBtn =
      this.isMarketPlaceOwner &&
      this.isSellerSection() &&
      currentTab === "PENDING" &&
      !this.isOwnerCreatingRequest;

    return (
      <div>
        <Helmet
          title={
            this.props.method === "edit"
              ? "Zopping - Edit Product"
              : "Zopping - Add Product"
          }
        />
        <Dialog
          show={this.state.deleteProductDialogShown}
          title={""}
          information={getMessage("product.form.delete.message")}
          onOk={this.deleteProduct}
          close={this.hideProductDeleteConfirmation}
          closeText={getMessage("cancel.text")}
          okText={getMessage("product.form.delete.confirmText")}
        />
        <Dialog
          show={this.state.showReviewSubmissionDialog}
          className="success"
          title={getMessage("product.review.submission.successTitle")}
          information={getMessage(this.state.requestSubmissionMessage)}
          okText={getMessage("product.review.submission.okText")}
          onOk={() => {
            this.setState({ showReviewSubmissionDialog: false }, () => {
              this.props.onCancel();
            });
          }}
        />
        <Dialog
          show={this.state.deleteVariantDialogShown}
          title={""}
          information={getMessage("product.form.variant.delete.message")}
          onOk={() => {
            this.removeVariant(this.state.selectedVariant);
          }}
          close={this.hideVariantDeleteConfirmation}
          closeText={getMessage("cancel.text")}
          okText={getMessage("product.form.delete.confirmText")}
        />
        <Popup
          show={this.state.showRejectReasonForm}
          heading={getMessage("product.request.reject.form.heading")}
          close={() => {
            this.setState({ showRejectReasonForm: false, submitting: false });
          }}
          className="rejection-form"
        >
          <form
            onSubmit={(e) => {
              e && e.preventDefault();
              this.submitApprovalStatus({
                rejectionReason: this.state.values?.rejectionReason,
              });
            }}
          >
            <Textarea
              label={getMessage("product.request.reject.form.label")}
              placeholder={getMessage(
                "product.request.reject.form.placeholder"
              )}
              {...this.generateStateMappers({
                stateKeys: ["rejectionReason"],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
              })}
            />
            <button
              className="button image-selection-button reject-button"
              type="submit"
              disabled={!this.state.values?.rejectionReason}
            >
              {getMessage("product.request.reject.form.heading")}
            </button>
            <button
              className="button"
              onClick={() => {
                this.setState({
                  showRejectReasonForm: false,
                  submitting: false,
                });
              }}
            >
              {getMessage("category.form.cancelText")}
            </button>
          </form>
        </Popup>
        <Dialog
          show={this.state.deleteAllVariantsDialogShown}
          title={""}
          information={getMessage("product.form.all.variants.delete.message")}
          onOk={this.removeAllVariant}
          close={this.hideAllVariantsDeleteConfirmation}
          closeText={getMessage("cancel.text")}
          okText={getMessage("product.form.delete.confirmText")}
        />
        <Dialog
          show={this.state.showErrorDialog}
          information={this.state.apiError}
          close={this.hideApiErrorDialog}
          closeText={getMessage("okay.text")}
        />
        <Form className="product-form">
          <div className="basic-section form-row">
            <span className="product-details-header">
              {getMessage("product.form.details.header")}
            </span>
            <div className="flex-around">
              <Input
                className="product-name"
                label={getMessage("product.form.name.heading")}
                placeholder={getMessage("product.form.name.placeholder")}
                name="name"
                type="text"
                required
                pattern={namePattern}
                siblings={
                  this.props.options && this.props.options.brands
                    ? {
                        before: [
                          <BrandSearch
                            key="brand-name"
                            className="brand-name"
                            responseKey="brand"
                            placeholder={getMessage(
                              "product.form.brand.placeholder"
                            )}
                            name="brand"
                            createButton={!isExtensionEnabled("MarketPlace")}
                            {...this.generateStateMappers({
                              stateKeys: ["brand"],
                              loseEmphasisOnFill: true,
                            })}
                            readOnly={this.isInputEditable}
                          />,
                        ],
                      }
                    : null
                }
                {...this.generateStateMappers({
                  stateKeys: ["name"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage("input.requiredMessage"),
                  patternMismatch: getMessage(
                    "product.form.soldByWeight.pattern"
                  ),
                }}
                readOnly={this.isInputEditable}
              >
                {enabledSoldByWeight && !this.state.values.variants
                  ? getMessage("product.form.name.description")
                  : ""}
              </Input>
              <CategorySearch
                label={getMessage("product.filters.category.heading")}
                placeholder={getMessage("product.category.placeholder")}
                name="category"
                required
                responseKey="category"
                createButton={!isExtensionEnabled("MarketPlace")}
                {...this.generateStateMappers({
                  stateKeys: ["categories"],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
                validationStrings={{
                  valueMissing: getMessage(
                    "product.form.category.requiredMessage"
                  ),
                }}
                readOnly={this.isInputEditable}
                multiple
              />
              {hasTags ? (
                <Searchable
                  label={getMessage("product.filters.tag.heading")}
                  placeholder={getMessage("product.form.tag.placeholder")}
                  name="tag"
                  searchUrl="/catalogue-service/tag"
                  valueKey="id"
                  responseKey="tag"
                  nameKey="name"
                  searchKey="name"
                  transformResponse={(response) => response.data?.tag || []}
                  createButton={!isExtensionEnabled("MarketPlace")}
                  multiple
                  {...this.generateStateMappers({
                    stateKeys: ["tags"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  readOnly={this.isInputEditable}
                />
              ) : null}
              {isMarketPlaceOwner() && !isSellerSection() && (
                <Searchable
                  label={getMessage(
                    "product.filters.permissableSeller.heading"
                  )}
                  placeholder={getMessage(
                    "product.filters.permissableSeller.placeholder"
                  )}
                  name="seller"
                  searchUrl="/account-service/seller"
                  valueKey="id"
                  responseKey="seller"
                  nameKey="name"
                  searchKey="name"
                  transformResponse={(response) => response.data?.seller}
                  multiple
                  {...this.generateStateMappers({
                    stateKeys: ["permissableSellers"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                />
              )}
              {isSellerSection() &&
                !isMarketPlaceSeller() &&
                !this.isOwnerCreatingRequest && (
                  <Input
                    label={getMessage("product.filters.sellerName.heading")}
                    name="sellerName"
                    {...this.generateStateMappers({
                      stateKeys: ["sellerName"],
                      loseEmphasisOnFill: true,
                    })}
                    readOnly
                  />
                )}
              {preOrderSupport && (
                <Input
                  className="handling-days"
                  label={getMessage("product.form.handlingDays.label")}
                  placeholder={getMessage(
                    "product.form.handlingDays.placeholder"
                  )}
                  name="handling-days"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ["handlingDays"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={{
                    valueMissing: getMessage("input.requiredMessage"),
                  }}
                  readOnly={this.isInputEditable}
                />
              )}
              {bulkOrderSuppport && (
                <Input
                  className="bulk-threshold"
                  label={getMessage("product.form.bulkThreshold.label")}
                  placeholder={getMessage(
                    "product.form.bulkThreshold.placeholder"
                  )}
                  name="bulk-order-threshold"
                  type="number"
                  min={0}
                  {...this.generateStateMappers({
                    stateKeys: ["bulkOrderThreshold"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  validationStrings={{
                    valueMissing: getMessage("input.requiredMessage"),
                  }}
                  readOnly={this.isInputEditable}
                />
              )}
              {hasSoldByWeight ? (
                <Checkbox
                  label={getMessage("")}
                  inlineLabel={getMessage("Sold By Weight")}
                  name={`sold-by-weight`}
                  {...this.generateStateMappers({
                    stateKeys: ["soldByWeight"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  readOnly={this.isInputEditable}
                />
              ) : null}
            </div>
          </div>
          {tabs && (
            <div className="description-tabs">
              {Object.keys(tabs).map((key) => {
                return (
                  <span
                    key={key}
                    className={selected === tabs[key] ? "selected" : ""}
                    onClick={() => {
                      this.setState({ selected: tabs[key] });
                    }}
                  >
                    {tabs[key]}
                  </span>
                );
              })}
            </div>
          )}
          {tabs && selected === tabs.description && (
            <div className="flex-item">
              {isExtensionEnabled("MarketPlace") && (
                <MetaDataFormFields
                  metaDataWithType={this.marketPlaceProductMetadata}
                  className="metadata-section"
                  page="product-form"
                  _this={this}
                />
              )}
              <RichEditor
                placeholder={getMessage("product.description.placeholder")}
                value={importText(this.state.values.description)}
                onChange={(value) => this.updateState(["description"], value)}
              />
            </div>
          )}
          {tabs && selected === tabs.otherDetails && (
            <>
              {isExtensionEnabled("SubscriptionProducts") && (
                <div className="recurring-product-container">
                  <Checkbox
                    className="extension-toggle"
                    inlineLabel={getMessage("recuring.product.label")}
                    name="shipRocketExtension"
                    controlled
                    value={this.state?.isRecurringProduct}
                    onChange={this.toggleIsRecurringProduct}
                    disabled={this.isInputEditable}
                  />

                  {this.state.isRecurringProduct && (
                    <Input
                      className="purchase-frequency"
                      label={getMessage("Purchase Frequency")}
                      placeholder={getMessage("Enter Frequency")}
                      name="purchase-frequency"
                      type="number"
                      min={0}
                      siblings={{
                        after: [
                          <div
                            key="purchase-frequency-type"
                            className="purchase-frequency-select"
                          >
                            <Select
                              className="frequency-type-select"
                              name="type"
                              options={FREQUENCY_TYPE}
                              {...this.generateStateMappers({
                                stateKeys: ["purchaseFrequencyData", "type"],
                              })}
                            />
                          </div>,
                        ],
                      }}
                      {...this.generateStateMappers({
                        stateKeys: ["purchaseFrequencyData", "frequency"],
                        validationType: VALIDATION_TYPES.ONSUBMIT,
                        loseEmphasisOnFill: true,
                      })}
                      validationStrings={{
                        valueMissing: getMessage("input.requiredMessage"),
                      }}
                      readOnly={this.isInputEditable}
                    />
                  )}
                </div>
              )}
              <MetaDataFormFields
                metaDataWithType={this.productMetaDataWithType?.product}
                className={`metadata-section other-details ${
                  this.isInputEditable && "not-editable"
                }`}
                page="product-form"
                _this={this}
              />
            </>
          )}
          <div className={supportVariants ? "variant-section" : "no-variants"}>
            <div className={supportVariants ? "variant-details" : null}>
              {supportVariants && (
                <div className="toggle-section">
                  <span>{getMessage("product.form.variant.heading")}</span>
                  <Toggle
                    name="variant"
                    className="variant-toggle"
                    value={this.state.variantToggle}
                    onChange={this.onToggleChange}
                    icons={false}
                    readOnly={this.isInputEditable}
                  />
                </div>
              )}
              {(this.getState(["variants"]) || [{}]).map(
                (variant, variantIndex, arr) => (
                  <div
                    key={`variant-${variant._key || variantIndex}`}
                    className={
                      variantIndex === this.state.selectedVariant
                        ? ""
                        : "hidden"
                    }
                  >
                    {this.state.variantToggle && !this.isMarketPlaceSeller && (
                      <div className="variant-add-section">
                        <div className="variant-input-section">
                          {supportVariants
                            ? getVariantInputFields(variantIndex)
                            : null}
                        </div>
                        <div className="edit-variant-container">
                          {this.state.editVariant && (
                            <div className="edit-variant">
                              {getEditVariantInputs()}
                            </div>
                          )}
                        </div>
                        {!this.state.editVariant && (
                          <div className="flex-around variants-tab">
                            {supportVariants &&
                            ((this.props.method === "add" &&
                              (this.getState(["variants"]) || []).length > 0) ||
                              (this.props.method === "edit" &&
                                ((this.getState(["variants"]) || []).length >
                                  1 ||
                                  ((this.getState(["variants"]) || [])
                                    .length === 1 &&
                                    this.getState(["variants"])[0].name)))) ? (
                              <React.Fragment>
                                <Tabs
                                  items={(
                                    this.getState(["variants"]) || []
                                  ).map((variant, index, arr) => (
                                    <span>
                                      {variant.name || "*"}
                                      {arr.length > 1 &&
                                      this.state.selectedVariant === index ? (
                                        <span
                                          alt="x"
                                          onClick={
                                            this.showVariantDeleteConfirmation
                                          }
                                          className="variant-delete-icon"
                                        >
                                          &times;
                                        </span>
                                      ) : null}
                                    </span>
                                  ))}
                                  active={this.state.selectedVariant}
                                  onClick={(selectedVariant) => {
                                    this.setState({ selectedVariant });
                                  }}
                                />
                                <button
                                  type="button"
                                  className="variant-edit"
                                  onClick={() => {
                                    this.handleEditBtnClick();
                                  }}
                                >
                                  <img src={editIcon} alt="edit-variant" />
                                </button>
                              </React.Fragment>
                            ) : null}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="image-updload-section">
                      {(["add", "edit"].includes(this.props.method) ||
                        this.getState([
                          "variants",
                          variantIndex,
                          "clientItemId",
                        ])) && (
                        <Input
                          type="text"
                          name={`variant-client-item.id-${variantIndex}`}
                          label={clientItemLabel}
                          placeholder={`Enter ${clientItemLabel}`}
                          readOnly={
                            checkEditStatus(variantIndex) ||
                            this.isInputEditable
                          }
                          className="client-item-id-field"
                          {...this.generateStateMappers({
                            stateKeys: this.getState(["variants"])
                              ? ["variants", variantIndex, "clientItemId"]
                              : ["clientItemId"],
                            loseEmphasisOnFill: true,
                          })}
                        />
                      )}
                      <MultiTextInput
                        label={getMessage(
                          "product.form.variant.barcodes.heading"
                        )}
                        placeholder={getMessage(
                          "product.form.variant.barcodes.heading"
                        )}
                        name="barcodes"
                        className="barcodes-field"
                        {...this.generateStateMappers({
                          stateKeys: ["variants", variantIndex, "barcodes"],
                          validationType: VALIDATION_TYPES.ONSUBMIT,
                          loseEmphasisOnFill: true,
                        })}
                        readOnly={this.isInputEditable}
                      />
                      {isExtensionEnabled("MarketPlace") && (
                        <MetaDataFormFields
                          metaDataWithType={this.marketPlaceVariantMetadata}
                          className="metadata-section"
                          page="product-form"
                          readOnly={this.isInputEditable}
                          stateKeys={
                            this.getState(["variants"])
                              ? ["variants", variantIndex, "metaData"]
                              : ["metaData"]
                          }
                          _this={this}
                        />
                      )}
                      {isExtensionEnabled("MarketPlace") && (
                        <Input
                          type="text"
                          name={`variant-low-stock.id-${variantIndex}`}
                          label={getMessage("product.form.lowStock")}
                          placeholder={getMessage(
                            "product.form.lowStock.placeholder"
                          )}
                          readOnly={
                            checkLowStockEditStatus(variantIndex) ||
                            this.isInputEditable
                          }
                          className="low-stock-field"
                          {...this.generateStateMappers({
                            stateKeys: this.getState(["variants"])
                              ? ["variants", variantIndex, "threshold"]
                              : ["threshold"],
                            validationType: VALIDATION_TYPES.ONSUBMIT,
                            loseEmphasisOnFill: true,
                          })}
                        />
                      )}
                      {this.state.variantToggle &&
                      this.productMetaDataWithType?.variant ? (
                        <div className="additional-variant">
                          <div className="heading">
                            {getMessage(
                              "product.form.additional.details.heading"
                            )}
                          </div>
                          <div className="content">
                            <MetaDataFormFields
                              metaDataWithType={
                                this.productMetaDataWithType?.variant
                              }
                              className={`metadata-section ${
                                this.isInputEditable && "not-editable"
                              }`}
                              page="product-form"
                              stateKeys={["variants", variantIndex, "metaData"]}
                              _this={this}
                            />
                          </div>
                        </div>
                      ) : null}

                      <ImageUpload
                        className="variant-image"
                        label={getMessage("product.form.variant.image.heading")}
                        placeholder={getMessage(
                          "product.form.variant.image.placeholder"
                        )}
                        name={`variant-${variantIndex}-image`}
                        multiple
                        isProductCreatePage
                        {...this.generateStateMappers({
                          stateKeys: ["variants", variantIndex, "images"],
                          validationType: VALIDATION_TYPES.ONSUBMIT,
                        })}
                        validationStrings={{
                          valueMissing: getMessage("input.requiredMessage"),
                          isUploading: getMessage("input.isUploading"),
                        }}
                      ></ImageUpload>
                    </div>
                    <div className="store-related-details">
                      {
                        // hasMultipleStores ? (
                        //   variant.storeSpecificData || []
                        // ) : (
                        //   variant.storeSpecificData && variant.storeSpecificData.length
                        //     ? variant.storeSpecificData
                        //     : [{}]
                        // )
                        // (variant.storeSpecificData && Object.keys(variant.storeSpecificData)) || []
                        ((stores && Object.keys(stores)) || []).map(
                          (storeId, storeIndex) => (
                            <div
                              key={storeIndex}
                              className="store-details-row form-row"
                            >
                              {stores &&
                              stores[storeId] &&
                              stores[storeId].name ? (
                                <div className="store-name-container">
                                  <img src={shopIcon} alt="shop-icon" />
                                  <span className="store-name">
                                    {stores[storeId].name}
                                  </span>
                                  <span className="store-toggle">
                                    <Toggle
                                      className={"product-status"}
                                      name={
                                        this.getState([
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "status",
                                        ]) === "ENABLED"
                                          ? "ENABLED"
                                          : "HIDDEN"
                                      }
                                      icons={false}
                                      value={
                                        this.getState([
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "status",
                                        ]) === "ENABLED" ||
                                        (!isExtensionEnabled(
                                          "MultiStoreSupport"
                                        ) &&
                                          !this.getState([
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "status",
                                          ]))
                                          ? true
                                          : undefined
                                      }
                                      onChange={() => {
                                        let status =
                                          this.getState([
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "status",
                                          ]) === "ENABLED" ||
                                          (!isExtensionEnabled(
                                            "MultiStoreSupport"
                                          ) &&
                                            !this.getState([
                                              "variants",
                                              variantIndex,
                                              "storeSpecificData",
                                              storeId,
                                              "status",
                                            ]));
                                        this.setState(
                                          { status: !status },
                                          () => {
                                            this.updateState(
                                              [
                                                "variants",
                                                variantIndex,
                                                "storeSpecificData",
                                                storeId,
                                                "status",
                                              ],
                                              this.state.status
                                                ? "ENABLED"
                                                : "HIDDEN"
                                            );
                                          }
                                        );
                                      }}
                                      readOnly={this.isInputEditable}
                                    />
                                  </span>
                                </div>
                              ) : null}
                              {isExtensionEnabled("MarketPlace") && (
                                <>
                                  <div className="store-specific-details stock-related-details">
                                    <div className="heading stock-helper">
                                      {getMessage(
                                        "product.dealer.price.header"
                                      )}
                                    </div>
                                    <div className="details">
                                      <Input
                                        label={getMessage(
                                          "product.form.variant.price.header"
                                        )}
                                        placeholder={getMessage(
                                          "product.form.variant.sgst.placeholder"
                                        )}
                                        name={`variant-${variantIndex}-store-${storeIndex}-availability`}
                                        type="number"
                                        // required
                                        min={0}
                                        prefix={
                                          getSession().organization.currency
                                            .symbol
                                        }
                                        {...this.generateStateMappers({
                                          stateKeys: [
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "dealerPrice",
                                          ],
                                          validationType:
                                            VALIDATION_TYPES.ONSUBMIT,
                                          loseEmphasisOnFill: true,
                                        })}
                                        validationStrings={{
                                          valueMissing: getMessage(
                                            "input.requiredMessage"
                                          ),
                                        }}
                                        readOnly={this.isInputEditable}
                                      />
                                    </div>
                                  </div>
                                  <div className="store-specific-details stock-related-details">
                                    <div className="heading stock-helper">
                                      {getMessage("Suggested Retail Price")}
                                    </div>
                                    <div className="details">
                                      <Input
                                        label={getMessage(
                                          "product.form.variant.price.header"
                                        )}
                                        placeholder={getMessage(
                                          "product.form.variant.sgst.placeholder"
                                        )}
                                        name={`variant-${variantIndex}-store-${storeIndex}-availability`}
                                        type="number"
                                        // required
                                        min={0}
                                        prefix={
                                          getSession().organization.currency
                                            .symbol
                                        }
                                        {...this.generateStateMappers({
                                          stateKeys: [
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "mrp",
                                          ],
                                          validationType:
                                            VALIDATION_TYPES.ONSUBMIT,
                                          loseEmphasisOnFill: true,
                                        })}
                                        validationStrings={{
                                          valueMissing: getMessage(
                                            "input.requiredMessage"
                                          ),
                                        }}
                                        readOnly={this.isInputEditable}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {!isExtensionEnabled("MarketPlace") && (
                                <div className="store-specific-details price-related-details">
                                  <div className="heading">
                                    {getMessage(
                                      "product.form.variant.price.header"
                                    )}
                                  </div>
                                  <div className="details">
                                    <Input
                                      label={getMessage(
                                        "product.form.variant.price.heading"
                                      )}
                                      placeholder={getMessage(
                                        "product.form.variant.price.placeholder"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-price`}
                                      type="number"
                                      step="0.01"
                                      min={0}
                                      // required
                                      prefix={
                                        getSession().organization.currency
                                          .symbol
                                      }
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "mrp",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                      validationStrings={{
                                        valueMissing: getMessage(
                                          "input.requiredMessage"
                                        ),
                                      }}
                                    />
                                    <Input
                                      label={getMessage(
                                        "product.form.variant.discount.heading"
                                      )}
                                      placeholder={getMessage(
                                        "product.form.variant.price.placeholder"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-discount`}
                                      type="number"
                                      step="0.01"
                                      min={0}
                                      max={this.getState([
                                        "variants",
                                        variantIndex,
                                        "storeSpecificData",
                                        storeId,
                                        "mrp",
                                      ])}
                                      prefix={
                                        getSession().organization.currency
                                          .symbol
                                      }
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "discount",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                      validationStrings={{
                                        valueMissing: getMessage(
                                          "input.requiredMessage"
                                        ),
                                        rangeOverflow: getMessage(
                                          "product.form.discount.error"
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {this.isSellerSection() && storeIndex === 0 && (
                                <div className="store-specific-details price-related-details">
                                  <div className="heading">
                                    {getMessage(
                                      "product.form.seller.price.heading"
                                    )}
                                  </div>
                                  <div className="details selling-price">
                                    <Input
                                      label={getMessage(
                                        "product.form.variant.price.heading"
                                      )}
                                      placeholder={getMessage(
                                        "product.form.variant.price.placeholder"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-price`}
                                      type="number"
                                      step="0.01"
                                      min={0}
                                      required={this.isSellerSection()}
                                      prefix={
                                        getSession().organization.currency
                                          .symbol
                                      }
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "sellingPrice",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                      validationStrings={{
                                        valueMissing: getMessage(
                                          "input.requiredMessage"
                                        ),
                                      }}
                                      value={
                                        this.state.values.variants?.[
                                          variantIndex
                                        ]?.storeSpecificData?.[storeId]
                                          ?.sellingPrice
                                      }
                                      onChange={(e) =>
                                        this.handleSellingPrice(
                                          e,
                                          variantIndex,
                                          storeId,
                                          "sellingPrice"
                                        )
                                      }
                                    />
                                    <Input
                                      label={getMessage(
                                        "product.form.variant.discount.heading"
                                      )}
                                      placeholder={getMessage(
                                        "product.form.variant.price.placeholder"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-discount`}
                                      type="number"
                                      step="0.01"
                                      min={0}
                                      max={this.getState([
                                        "variants",
                                        variantIndex,
                                        "storeSpecificData",
                                        storeId,
                                        "mrp",
                                      ])}
                                      prefix={
                                        getSession().organization.currency
                                          .symbol
                                      }
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "sellerDiscount",
                                        ],
                                        loseEmphasisOnFill: true,
                                      })}
                                      value={
                                        this.state.values.variants?.[
                                          variantIndex
                                        ]?.storeSpecificData?.[storeId]
                                          ?.sellerDiscount
                                      }
                                      onChange={(e) =>
                                        this.handleSellingPrice(
                                          e,
                                          variantIndex,
                                          storeId,
                                          "sellerDiscount"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="selling-price-profit-seller-margin-wrp">
                                    <div className="selling-price-profit-margin">
                                      {getMessage("product.form.sellingMargin")}
                                      {
                                        getSession().organization.currency
                                          .symbol
                                      }
                                      {this.state.sellingMargin}
                                    </div>
                                    <div className="selling-price-profit-margin">
                                      {getMessage("product.form.profitMargin")}
                                      {this.state.profitMargin}%
                                    </div>
                                    <div className="selling-price-profit-margin minimum-profit-margin">
                                      {this.state.isMinimumProfitMargin && (
                                        <>
                                          {getMessage(
                                            "product.form.minimumProfitMargin.error",
                                            {
                                              value:
                                                this.state.values.variants?.[0]
                                                  ?.metaData[
                                                  "Minimum Profit Margin"
                                                ],
                                            }
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {getSession().organization.currency.name ===
                                "INR" && (
                                <div className="store-specific-details tax-related-details">
                                  <div className="heading">
                                    {getMessage(
                                      "product.form.variant.tax.header"
                                    )}
                                  </div>
                                  <div className="details">
                                    {this.state?.taxes?.map((tax) => {
                                      return (
                                        <Input
                                          label={tax}
                                          placeholder={getMessage(
                                            "product.form.variant.sgst.placeholder"
                                          )}
                                          name={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                                          key={tax}
                                          type="number"
                                          step="0.01"
                                          min={0}
                                          max={100}
                                          className="tax-field"
                                          suffix="%"
                                          {...this.generateStateMappers({
                                            stateKeys: [
                                              "variants",
                                              variantIndex,
                                              "storeSpecificData",
                                              storeId,
                                              "tax",
                                              tax.toLowerCase(),
                                            ],
                                            loseEmphasisOnFill: true,
                                            validationType:
                                              VALIDATION_TYPES.ONSUBMIT,
                                          })}
                                          readOnly={this.isInputEditable}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                              {getSession().organization.currency.name !==
                                "INR" &&
                                this.state.taxArray &&
                                this.state.taxArray.length > 0 && (
                                  <div className="store-specific-details tax-related-details">
                                    <div className="heading">
                                      {getMessage(
                                        "product.form.variant.tax.header"
                                      )}
                                    </div>
                                    <div className="details">
                                      {this.state.taxArray.map((tax, i) => {
                                        return (
                                          <Input
                                            label={tax.toUpperCase()}
                                            placeholder={getMessage(
                                              "product.form.variant.sgst.placeholder"
                                            )}
                                            name={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                                            key={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                                            type="number"
                                            step="0.01"
                                            min={0}
                                            max={100}
                                            className="tax-field"
                                            suffix="%"
                                            {...this.generateStateMappers({
                                              stateKeys: [
                                                "variants",
                                                variantIndex,
                                                "storeSpecificData",
                                                storeId,
                                                "tax",
                                                tax,
                                              ],
                                              loseEmphasisOnFill: true,
                                            })}
                                            readOnly={this.isInputEditable}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              {getSession().organization.currency.name !==
                                "INR" &&
                                this.state.taxes &&
                                this.state.taxes.length > 0 && (
                                  <div className="store-specific-details tax-related-details">
                                    <div className="heading">
                                      {getMessage(
                                        "product.form.variant.tax.header"
                                      )}
                                    </div>
                                    <div className="details">
                                      {this.state.taxes.map((tax, i) => {
                                        return (
                                          <Input
                                            label={tax.toUpperCase()}
                                            placeholder={getMessage(
                                              "product.form.variant.sgst.placeholder"
                                            )}
                                            name={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                                            key={`variant-${variantIndex}-store-${storeIndex}-${tax}`}
                                            type="number"
                                            step="0.01"
                                            min={0}
                                            max={100}
                                            className="tax-field"
                                            suffix="%"
                                            {...this.generateStateMappers({
                                              stateKeys: [
                                                "variants",
                                                variantIndex,
                                                "storeSpecificData",
                                                storeId,
                                                "tax",
                                                tax,
                                              ],
                                              loseEmphasisOnFill: true,
                                            })}
                                            readOnly={this.isInputEditable}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              {hasInstoreProcessing && (
                                <div className="store-specific-details product-location-details">
                                  <div className="heading">
                                    {getMessage(
                                      "product.form.variant.location.header"
                                    )}
                                  </div>
                                  <div className="details">
                                    <Input
                                      label={getMessage(
                                        "product.form.variant.aisle.heading"
                                      )}
                                      placeholder={getMessage(
                                        "product.form.variant.aisle.heading"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-aisle`}
                                      type="text"
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "location",
                                          "aisle",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                      readOnly={this.isInputEditable}
                                    />
                                    <Input
                                      label={getMessage(
                                        "product.form.variant.rack.heading"
                                      )}
                                      placeholder={getMessage(
                                        "product.form.variant.rack.heading"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-rack`}
                                      type="text"
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "location",
                                          "rack",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                      readOnly={this.isInputEditable}
                                    />
                                    <Input
                                      label="position"
                                      placeholder={getMessage(
                                        "position.text.label"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-position`}
                                      type="text"
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "location",
                                          "position",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="store-specific-details stock-related-details">
                                <div className="heading stock-helper">
                                  {getMessage(
                                    "product.form.variant.stock.header"
                                  )}
                                </div>
                                <div className="details">
                                  {booleanStock ? (
                                    <Checkbox
                                      label={getMessage(
                                        "product.form.variant.availability.heading"
                                      )}
                                      inlineLabel={getMessage(
                                        "product.form.variant.availability.label"
                                      )}
                                      name={`variant-${variantIndex}-store-${storeIndex}-availability`}
                                      {...this.generateStateMappers({
                                        stateKeys: [
                                          "variants",
                                          variantIndex,
                                          "storeSpecificData",
                                          storeId,
                                          "stock",
                                        ],
                                        validationType:
                                          VALIDATION_TYPES.ONSUBMIT,
                                        loseEmphasisOnFill: true,
                                      })}
                                      controlled
                                      readOnly={this.isInputEditable}
                                    />
                                  ) : (
                                    <React.Fragment>
                                      <Input
                                        label={getMessage(
                                          "product.form.variant.stock.quantity.heading"
                                        )}
                                        placeholder={getMessage(
                                          "product.form.variant.sgst.placeholder"
                                        )}
                                        name={`variant-${variantIndex}-store-${storeIndex}-availability`}
                                        type="number"
                                        // required
                                        min={0}
                                        {...this.generateStateMappers({
                                          stateKeys: [
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "stock",
                                          ],
                                          validationType:
                                            VALIDATION_TYPES.ONSUBMIT,
                                          loseEmphasisOnFill: true,
                                        })}
                                        validationStrings={{
                                          valueMissing: getMessage(
                                            "input.requiredMessage"
                                          ),
                                        }}
                                        readOnly={
                                          this.isInputEditable ||
                                          this.getState([
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "unlimitedStock",
                                          ])
                                        }
                                      />
                                      <Checkbox
                                        label={getMessage(
                                          "product.form.variant.unlimitedAvailable.label"
                                        )}
                                        className="unlimited-stock"
                                        name={`variant-${variantIndex}-store-${storeIndex}-unlimitedAvailable`}
                                        {...this.generateStateMappers({
                                          stateKeys: [
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "unlimitedStock",
                                          ],
                                          validationType:
                                            VALIDATION_TYPES.ONSUBMIT,
                                          loseEmphasisOnFill: true,
                                        })}
                                        onChange={(event) => {
                                          this.updateState(
                                            [
                                              "variants",
                                              variantIndex,
                                              "storeSpecificData",
                                              storeId,
                                              "unlimitedStock",
                                            ],
                                            event
                                          );
                                          this.updateState(
                                            [
                                              "variants",
                                              variantIndex,
                                              "storeSpecificData",
                                              storeId,
                                              "stock",
                                            ],
                                            0
                                          );
                                        }}
                                        controlled
                                        value={
                                          this.getState([
                                            "variants",
                                            variantIndex,
                                            "storeSpecificData",
                                            storeId,
                                            "unlimitedStock",
                                          ]) || false
                                        }
                                        disabled={this.isInputEditable}
                                      />
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )
                      }
                      {this.state.loadingStores ? <Loader size="sm" /> : null}
                      {this.state.failedLoadingStores ? (
                        <div
                          className="stores-error-text"
                          onClick={this.getStores}
                        >
                          {getMessage("product.form.stores.fetchError")}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="form-actions">
            {this.state.values.id && !this.isSellerSection() ? (
              <button
                className="product-delete-button"
                onClick={this.showProductDeleteConfirmation}
              >
                <img src={deleteIcon} alt="x" className="product-delete-icon" />
                <span className="product-delete-button-text">
                  {getMessage("product.form.deleteText")}
                </span>
              </button>
            ) : null}
            <div className="form-buttons">
              {(!isExtensionEnabled("MarketPlace") ||
                (this.isMarketPlaceOwner && !this.isSellerSection()) ||
                this.isOwnerCreatingRequest) && (
                <SubmitButton disabled={this.state.submitting}>
                  {getMessage("product.form.submitText")}
                </SubmitButton>
              )}
              {isMarketPlaceSeller() && (
                <>
                  <SubmitButton disabled={this.state.submitting}>
                    {getMessage("product.form.review.submission")}
                  </SubmitButton>
                  <div className="draft-btn-wrp">
                    <SubmitButton disabled={this.state.submitting}>
                      {getMessage("product.form.save.draft")}
                    </SubmitButton>
                  </div>
                </>
              )}

              {isMarketPlaceOwner() && this.isSellerSection() && (
                <>
                  {showApproveBtn && (
                    <SubmitButton disabled={this.state.submitting}>
                      {getMessage("Approve")}
                    </SubmitButton>
                  )}
                  {showRejectBtn && (
                    <div className="reject-btn-wrp">
                      <SubmitButton disabled={this.state.submitting}>
                        {getMessage("Reject")}
                      </SubmitButton>
                    </div>
                  )}
                </>
              )}

              <CancelButton>
                {getMessage("product.form.cancelText")}
              </CancelButton>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export const STOCK_STRATEGIES = {
  BOOLEAN: "boolean",
  NUMERIC: "numeric",
};
