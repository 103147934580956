import React, { useState, useEffect } from "react";
import AndroidForm from "./AndriodForm";
import Loader from "../../../../../components/Loader";
import API from "../../../../../lib/api";
import SplashScreen from "../../../../../components/Layout/Carousel";

import "./style.css";

const SplashScreenComponent = ({ menu }) => {
  const [config, setConfig] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const apiRef = React.useRef(null);

  const loadData = () => {
    setShowLoader(true);
    const api = new API({
      url: "/config-service/config?category=androidAppConfig,iosAppConfig",
    });
    apiRef.current = api;

    api
      .get()
      .then((response) => {
        setShowLoader(false);
        setConfig(response.data?.config);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error(error);
        throw error;
      })
      .finally(() => setShowLoader(false));
  };

  useEffect(() => {
    loadData();

    return () => {
      if (apiRef.current) {
        apiRef.current.cancel();
      }
    };
  }, []);

  return (
    <div className="splash-wrapper">
      {showLoader ? (
        <Loader />
      ) : (
        <AndroidForm
          configData={{
            ...config.androidAppConfig,
            ...config.iosAppConfig,
          }}
        />
      )}
    </div>
  );
};

const SplashScreenWrapper = () => {
  return {
    preview: SplashScreen,
    fields: (props) => <SplashScreenComponent {...props} />,
  };
};

export default SplashScreenWrapper;
