import React from "react";
import { Input, Upload } from "../../../../components/Form";
import Tabs from "../../../../components/Tabs";
import GridCollectionLayout from "../../../../components/Layout/GridCollection";
import { getMessage } from "../../../../lib/translator";

const GridCollection = (props) => {
  const { getState, updateState, parent } = props;

  const activeIndex = Number(getState(["activeIndex"])) || 0;
  const emptySlide = {
    imageUrl: "",
    imgText: "",
    link: "",
    linkText: "",
    bannerText: "",
  };
  const slides = getState(["images"]) || [emptySlide];
  const currentSlide = slides[activeIndex] || emptySlide;

  if (!getState(["images"])) {
    updateState(["images"], slides); // TODO: Move this to componentWillMount of layout
  }

  // Since updateStateRecursively had some issues with this structure
  const newState = (key, value) => {
    parent.setState((prevState) => {
      const newState = Object.assign({}, prevState);
      newState.values.images[activeIndex][key] = value;
      return prevState;
    });
  };
  const showDelete = slides && slides.length > 1;
  return (
    <>
      <div className="form-sections">
        <div className="form-section">
          <Input
            label="title"
            placeholder="Please enter title here"
            value={getState(["title"]) || ""}
            onChange={(value) => {
              updateState(["title"], value);
            }}
          />
        </div>
      </div>
      <div>
        <div className="flex-around">
          <Tabs
            items={slides.map((slide, index) => (
              <span key={index}>
                image {index + 1}
                {showDelete && (
                  <span
                    className="delete-tab"
                    onClick={() => {
                      slides.splice(index, 1);
                      updateState(
                        ["activeIndex"],
                        index !== 0 && index === slides.length
                          ? index - 1
                          : index
                      );
                    }}
                  >
                    &times;
                  </span>
                )}
              </span>
            ))}
            default={activeIndex}
            active={activeIndex}
            onClick={(value) => {
              updateState(["activeIndex"], value);
            }}
          />
          <button
            className="add-tab"
            onClick={(e) => {
              e.preventDefault();
              const slidesCount = slides.length;
              updateState(["images"], slides.concat(emptySlide));
              updateState(["activeIndex"], slidesCount);
            }}
          >
            + {getMessage("imageCollection.addImage")}
          </button>
        </div>
        <div className="form-sections image-with-text-layout">
          <div className="form-section">
            <Input
              label="Image Text"
              placeholder="Enter your image text here"
              value={currentSlide["imgText"]}
              onChange={(value) => {
                newState("imgText", value);
              }}
            />
            <Input
              label="link"
              placeholder="Enter your link"
              value={currentSlide["link"]}
              onChange={(value) => {
                newState("link", value);
              }}
            />
            <Input
              label="Link Text"
              placeholder="Enter your link text here"
              value={currentSlide["linkText"]}
              onChange={(value) => {
                newState("linkText", value);
              }}
            />
            <Input
              label="Banner Text"
              placeholder="Enter your banner text here"
              value={currentSlide["bannerText"]}
              onChange={(value) => {
                newState("bannerText", value);
              }}
            />
          </div>
          <div className="form-section layout-visibility-add-margin">
            <Upload
              label="image"
              placeholder="Click here to upload or drag your images"
              value={currentSlide["imageUrl"]}
              onChange={(value) => {
                newState("imageUrl", value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const GridCollectionWrp = () => {
  return {
    preview: GridCollectionLayout,
    fields: ({ getState, updateState, parent }) => (
      <GridCollection
        getState={getState}
        updateState={updateState}
        parent={parent}
      />
    ),
  };
};

export default GridCollectionWrp;
