import React from "react";
import img from "./image.png";

export default function SplashScreen(props) {
  return (
    <div>
      <img src={img} alt="" />
    </div>
  );
}
