import React from "react";
import { BaseForm, Checkbox } from "../../../components/Form";
import API from "../../../lib/api";
import Loader from "../../../components/Loader";
import { isExtensionEnabled } from "../../../lib/auth";

function trimQueryString(str) {
  return str.split("&").filter(Boolean).join("&");
}

export default class Layout extends BaseForm {
  constructor(props) {
    super(props);
    this.preview =
      this.props.preview ||
      (() => {
        return null;
      });
    this.fields =
      this.props.fields ||
      (() => {
        return null;
      });
    this.state.values = this.props.data;
    this.state.values.showOriginal = this.props.data?.showOriginal;
    this.getValueLayouts = {
      CategoryCollection: {
        name: "category",
        key: "categoryIds",
      },
      BrandCollection: {
        name: "brand",
        key: "brandIds",
      },
      BlogCollection: {
        name: "blog",
        key: "blogIds",
      },
    };
  }
  componentDidMount() {
    const name = this.props.name;
    let data = this.props.data;
    let layout = this.getValueLayouts[name];
    if (layout && data[layout.key] && data[layout.key].length > 0) {
      this.setState({
        loading: true,
      });
      let param = "";
      let cparam = "";
      let tparam = "";
      data[layout.key] &&
        data[layout.key].forEach((id) => {
          if (layout.name === "category") {
            if (Number(id)) {
              cparam += `id=${id}&`;
            } else {
              let type = id && id[0] && id[0].toString();
              if (type === "C") {
                cparam += `id=${id && id.toString().slice(1)}&`;
              } else if (type === "T") {
                tparam += `id=${id && id.toString().slice(1)}&`;
              }
            }
          } else {
            param += `id=${id}&`;
          }
        });
      // trim extra & at the end
      param = trimQueryString(param);
      cparam = trimQueryString(cparam);
      tparam = trimQueryString(tparam);

      if (layout.name === "category") {
        cparam += "&paginate=false";
        tparam += "&paginate=false";
        const categoryApi = cparam
          ? new API({ url: `/catalogue-service/category?${cparam}` })
          : null;
        const tagApi =
          tparam && isExtensionEnabled("ProductTagSupport")
            ? new API({ url: `/catalogue-service/tag?${tparam}` })
            : null;
        Promise.all([categoryApi && categoryApi.get(), tagApi && tagApi.get()])
          .then(([categoryResponse, tagResponse]) => {
            let categories =
              (categoryResponse &&
                categoryResponse.data &&
                categoryResponse.data.category) ||
              [];
            categories = (categories || []).map((category) => {
              category.id = `C${category.id}`;
              return category;
            });
            let tags =
              (tagResponse && tagResponse.data && tagResponse.data.tag) || [];
            tags = (tags || []).map((tag) => {
              tag.name = `T ${tag.name}`;
              tag.id = `T${tag.id}`;
              return tag;
            });
            categories = [...categories, ...tags];
            let data = categories;
            let sortedData = [];
            // We are sorting the get incoming data based on the props data categoryIds
            if (this.props.sortable) {
              this.props.data &&
                this.props.data[layout.key] &&
                this.props.data[layout.key].map((id) => {
                  data.map((item) => {
                    if (item.id === id) {
                      sortedData.push(item);
                    }
                    return null;
                  });
                  return null;
                });
            }
            let values = Object.assign({}, this.state.values);
            values[layout.name] = this.props.sortable ? sortedData : data;
            this.setState({
              values,
              loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (layout.name === "blog") {
        param += "&paginate=false";
        const blogApi = param
          ? new API({ url: `/blog-service/blog?${cparam}` })
          : null;

        blogApi
          .get()
          .then((response) => {
            let blogs = response?.data?.blog || [];
            blogs = (blogs || []).map((blog) => {
              blog.id = `${blog.id}`;
              return blog;
            });

            let data = blogs;
            let sortedData = [];
            // We are sorting the get incoming data based on the props data categoryIds
            if (this.props.sortable) {
              this.props.data &&
                this.props.data[layout.key] &&
                this.props.data[layout.key].map((id) => {
                  data.map((item) => {
                    if (item.id === id) {
                      sortedData.push(item);
                    }
                    return null;
                  });
                  return null;
                });
            }
            let values = Object.assign({}, this.state.values);
            values[layout.name] = this.props.sortable ? sortedData : data;
            this.setState({
              values,
              loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // this is for brand collection
        if (layout.name === "brand") {
          param += `&paginate=false`;
        }
        const api = new API({
          url: `/catalogue-service/${layout.name}?${param}`,
        });
        const sortedKeys = data[layout.key];
        api
          .get()
          .then((response) => {
            let data = response.data[layout.name];
            let values = Object.assign({}, this.state.values);

            // sort the response in the same order as sortedKeys
            data =
              data &&
              data.sort(
                (a, b) => sortedKeys.indexOf(a.id) - sortedKeys.indexOf(b.id)
              );

            values[layout.name] = data;
            this.setState({
              values,
              loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    if (name === "ProductCollection") {
      let entityObj = {};
      let promises = [];
      if (data.category) {
        const api = new API({
          url: `/catalogue-service/category?slug=${data.category}`,
        });
        if (data.category === "CURRENT") {
          entityObj.category = {
            id: new Date().getTime(),
            name: "current",
            displayName: "current",
            slug: "CURRENT",
          };
        } else {
          promises.push(api.get());
        }
      }
      if (data.brand && isExtensionEnabled("MultiBrandSupport")) {
        const api = new API({
          url: `/catalogue-service/brand?slug=${data.brand}`,
        });
        if (data.brand === "CURRENT") {
          entityObj.brand = {
            id: new Date().getTime(),
            name: "current",
            displayName: "current",
            slug: "CURRENT",
          };
        } else {
          promises.push(api.get());
        }
      }
      if (data.tag && isExtensionEnabled("ProductTagSupport")) {
        const api = new API({ url: `/catalogue-service/tag?slug=${data.tag}` });
        if (data.tag === "CURRENT") {
          entityObj.tag = {
            id: new Date().getTime(),
            name: "current",
            displayName: "current",
            slug: "CURRENT",
          };
        } else {
          promises.push(api.get());
        }
      }
      Promise.all(promises)
        .then((responseArr) => {
          let values = JSON.parse(JSON.stringify(this.state.values));
          responseArr.map((response) => {
            if (response.data.category) {
              entityObj.category = response.data.category[0];
            }
            if (response.data.brand) {
              entityObj.brand = response.data.brand[0];
            }
            if (response.data.tag) {
              entityObj.tag = response.data.tag[0];
            }
            return null;
          });
          values = { ...values, ...entityObj };

          this.setState({ values });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleChangeOriginal = (value) => {
    this.setState({
      values: { ...this.state.values, showOriginal: value },
    });
  };

  render() {
    let { Form } = this.components;
    let { SubmitButton, CancelButton } = this.buttons;
    let Preview = this.preview;
    const styles = {
      transform: `translate3d(0, ${this.props.top - 65}px, 0)`, // 65px to accommodate for the page heading
    };
    const { validations: { layoutBackground: { backgroundColor } = {} } = {} } =
      this.state;
    const disableSubmit = backgroundColor ? !backgroundColor?.valid : false;
    const loading = this.state.loading;
    const isOriginalAvailable = [
      "ImageSlideShow",
      "CategoryCollection",
    ].includes(this.props.name);
    return loading ? (
      <Loader />
    ) : (
      <Form>
        <h1>{this.props.name.replace(/([A-Z])/g, " $1").trim()}</h1>
        <div
          ref={(el) => {
            this.layoutElement = el;
          }}
          className="layout chosenAnimation"
          style={styles}
        >
          <Preview data={this.state.values} />
        </div>
        <div className={`editLayout revealAnimation ${this.props.name}`}>
          {isOriginalAvailable && (
            <>
              <Checkbox
                className="checkbox-field"
                name="Original"
                controlled
                value={this.state?.values?.showOriginal}
                onChange={this.handleChangeOriginal}
                inlineLabel="Original"
              />
            </>
          )}
          {this.fields({
            getState: this.getState.bind(this),
            updateState: this.updateState.bind(this),
            registerValidation: this.registerValidation.bind(this),
            parent: this,
            page: this.props.page,
          })}
        </div>
        <div className="layout-footer revealAnimation">
          <CancelButton className="button">Cancel</CancelButton>
          {this.props?.onSaveClick ? (
            <button
              className="button primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.props.onSaveClick(this.state.values);
              }}
            >
              save
            </button>
          ) : (
            <SubmitButton
              disabled={disableSubmit || this.props.name === "Splash"}
            >
              Save
            </SubmitButton>
          )}
        </div>
      </Form>
    );
  }
}
