import React from "react";
import ProductCollection from "../../../../components/Layout/ProductCollection";
import { Input, Radio } from "../../../../components/Form";
import { getMessage } from "../../../../lib/translator";
const SellerCollectionLayout = () => {
  return {
    preview: ProductCollection,
    fields: function () {
      return (
        <div>
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage("themes.title")}
                placeholder="Type the collection title"
                {...this.generateStateMappers({
                  stateKeys: ["title"],
                })}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage("SUBTITLE")}
                placeholder="Enter the  subtitle"
                {...this.generateStateMappers({
                  stateKeys: ["subtitle"],
                })}
              />
            </div>
          </div>
          <div className="form-sections">
            <div className="form-section">
              <Radio
                name="layoutType"
                label={getMessage("themes.layoutType")}
                required
                options={[
                  {
                    text: getMessage("themes.grid"),
                    value: "GRID",
                  },
                  {
                    text: getMessage("themes.scroller"),
                    value: "SCROLLER",
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ["layoutType"],
                })}
              />
            </div>
            <div className="form-section">
              <Radio
                name="pagination"
                label={getMessage("themes.loadMoreType.seller")}
                required
                options={[
                  {
                    text: getMessage("themes.infiniteScroll"),
                    value: "INFINITE",
                  },
                  {
                    text: getMessage("themes.seeAll"),
                    value: "SEEALL",
                  },
                ]}
                {...this.generateStateMappers({
                  stateKeys: ["loadMoreType"],
                })}
              />
            </div>
          </div>
        </div>
      );
    },
  };
};

export default SellerCollectionLayout;
