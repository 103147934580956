import React, { Component } from "react";
import AuthenticatedPage from "../../../../containers/AuthenticatedPage";
import PlacementDetails from "./PlacementDetails";
import DeliveryDetails from "./DeliveryDetails";
import OrderItemsView from "./OrderItemsView";
import OrderInvoice from "./OrderInvoice";
import { Link } from "react-router-dom";
import PaymentDetails from "./PaymentDetails";
import RefundDetails from "./RefundDetails";
import PackingDetails from "./PackingDetails";
import MetaDataDetails from "./MetaDataDetails";
import { DropDown, DropDownItem, ICONS } from "../../../../components/DropDown";
import Loader from "../../../../components/Loader";
import { Dialog } from "../../../../components/Popup";
import { getReturnableStatuses } from "../../Returns";
import { tripStatus } from "../../Orders/Table/Views/tabularView";
import { utcToLocalTime, getStandradDateTime } from "../../../../lib/datetime";
import { getMessage } from "../../../../lib/translator";
import { registerInvoiceSockets } from "../../../../registerSocket";
import {
  getSession,
  isExtensionEnabled,
  getExtensionDetails,
  hasPermissions,
} from "../../../../lib/auth";
import API from "../../../../lib/api";
import "./style.css";
import checkHttpResponseCode from "../../../../utils/isApiCallSuccessfull";
import { getOrderPermissions } from "../Table";
import PDFViewer from "./PdfViewer";
import { isMarketPlaceOwner } from "../../../../lib/marketPlace";
import { GO_HOST } from "../../../../config/app";

const showConfig = () => {
  if (GO_HOST.includes("tamimimarkets.com")) return true;
  return false;
};

const deliveryDetailsEditableStatus = [
  "PENDING",
  "RETURNED",
  "PACKING",
  "PACKED",
  "PICKING",
  "PARTIALLY-PICKED",
  "PICKED",
  "CHECKING",
  "CHECKED",
  "DISPATCHED",
];
const invoiceDetailsStatus = ["RETURNED", "COMPLETED", "CANCELLED"];

function getMobileView() {
  return window.screen.width <= 480;
}

const getParams = () => {
  const params = {
    "includes[1]": "replaceItems",
  };

  if (isExtensionEnabled("ShipRocket")) {
    params["includes[2]"] = "courierDetails";
  }

  return params;
};

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      failed: false,
      orderCancellationDialog: false,
      orderCompletionDialog: false,
      invoiceShown: false,
      moveToPendingDialog: false,
      isOrderViewItemCollapsed: true,
      isMetaDataCollapsed: true,
      isPaymentDetailsCollapsed: true,
      isRefundDetailsCollapsed: true,
      invoiceUrl: null,
      isMobileView: getMobileView(),
      orderActions: getOrderPermissions(),
      courierParams: getParams(),
      itemAdditionStatus: "PENDING",
      itemRemovalStatus: "PENDING",
    };
    this.organization = getSession().organization;
    this.currency = this.organization.currency;

    this.getData = this.getData.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleMetaDataEdit = this.handleMetaDataEdit.bind(this);
    this.toggleCompletionDialog = this.toggleCompletionDialog.bind(this);
    this.toggleCancellationDialog = this.toggleCancellationDialog.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.metaDataExists = this.metaDataExists.bind(this);
    this.completeOrder = this.completeOrder.bind(this);
    this.showInvoice = this.showInvoice.bind(this);
    this.hideInvoice = this.hideInvoice.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.isMetaDataKeySet = this.isMetaDataKeySet.bind(this);
    this.toggleMoveToPendingDialog = this.toggleMoveToPendingDialog.bind(this);
    this.moveToPending = this.moveToPending.bind(this);
    this.mobileView = this.mobileView.bind(this);
  }
  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }

  async moveToPending() {
    this.modifyOrder(
      {
        status: "PENDING",
      },
      { "X-API-VERSION": 2 },
      "PENDING"
    ).then(() => {
      this.setState({ moveToPendingDialog: false });
    });
  }

  toggleMoveToPendingDialog() {
    this.setState((prevState) => {
      return {
        moveToPendingDialog: !prevState.moveToPendingDialog,
      };
    });
  }

  getData() {
    const { courierParams } = this.state;
    let referenceNumber = this.props.router.match.params.id;
    this.orderDetailsApi = new API({
      url: `/order-service/order/${referenceNumber}`,
    });
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.orderDetailsApi
          .get({
            "include[0]": "cancelledItems",
            "includes[0]": "reReturns",
            ...courierParams,
          })
          .then(
            (response) => {
              const order = response.data.order;
              if (order.createdAt) {
                order.createdAt = getStandradDateTime(
                  utcToLocalTime,
                  order.createdAt
                );
              }
              if (order.completedAt) {
                order.completedAt = getStandradDateTime(
                  utcToLocalTime,
                  order.completedAt
                );
              }
              if (order.updatedAt) {
                order.updatedAt = getStandradDateTime(
                  utcToLocalTime,
                  order.updatedAt
                );
              }
              this.setState({
                failed: false,
                data: order,
              });
            },
            (error) => {
              this.setState({ failed: true });
              if (error.code === 401) {
                throw error;
              }
            }
          )
          .then(() => {
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  }
  modifyOrder(params, headers = {}, newOrderStatus) {
    let referenceNumber = this.props.router.match.params.id;
    let api = new API({ url: `/order-service/order/${referenceNumber}` });
    return api
      .put(params, headers)
      .then(
        (response) => {
          if (response && response.data && response.data.order) {
            this.setState({ data: response.data.order, error: false });
          } else {
            if (newOrderStatus && response.status === "SUCCESS") {
              let newData = JSON.parse(JSON.stringify(this.state.data));
              newData.status = newOrderStatus;
              this.setState({
                data: newData,
              });
            }
          }
        },
        (error) => {
          this.setState({
            error: error.message,
          });
          throw error;
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }
  handleItemEdit(items) {
    return this.modifyOrder({
      orderItems: items
        .filter(
          (item) =>
            item.orderDetails.orderItemId ||
            item.orderDetails.orderedQuantity > 0
        )
        .map((item) => ({
          [item.orderDetails.orderItemId ? "orderItemId" : "itemId"]: item
            .orderDetails.orderItemId
            ? item.orderDetails.orderItemId
            : item.id,
          quantity: !item.orderDetails.orderItemId
            ? Number(item.orderDetails.orderedQuantity) /
              item.orderDetails.factorForPutRequest
            : Number(item.orderDetails.orderedQuantity),
        })),
    });
  }
  handleAddressChange(params, headers = {}) {
    return this.modifyOrder(params, headers);
  }

  handleMetaDataEdit(metadata) {
    return this.modifyOrder({ metaData: metadata });
  }

  toggleCompletionDialog() {
    this.setState((prevState) => {
      return {
        orderCompletionDialog: !prevState.orderCompletionDialog,
      };
    });
  }
  handleInvoice = (url) => {
    this.setState({ invoiceUrl: url, invoiceShown: true });
  };
  toggleCancellationDialog() {
    this.setState((prevState) => {
      return {
        orderCancellationDialog: !prevState.orderCancellationDialog,
      };
    });
  }
  completeOrder(url) {
    let productReturned = false;
    const referenceNumber = this.props.router.match.params.id;
    const redirectUrl = `/customer-support/orders/file-for-return/${referenceNumber}`;
    let { history } = this.props;
    if (this.state.data) {
      const { returnsByRE = [] } = this.state.data;
      productReturned = returnsByRE.length > 0;
    }
    if (productReturned) {
      history.push(redirectUrl);
    } else {
      this.modifyOrder({
        status: "COMPLETED",
      }).then(() => {
        this.setState({ orderCompletionDialog: false });
      });
    }
  }

  getInvoice = () => {
    let referenceNumber = this.props.router.match.params.id;
    let api = new API({
      url: `/order-service/invoice/${referenceNumber}?latestPdf=true`,
    });
    return api
      .get()
      .then(
        (response) => {
          this.setState({ invoiceUrl: response.data.invoice.pdfLink });
          this.setState({ invoiceShown: true });
        },
        (error) => {
          this.setState({
            error: error.message,
          });
          throw error;
        }
      )
      .catch((error) => {
        console.error(error);
      });
  };
  updateInvoice = (msg) => {
    if (!this) {
      return;
    }
    let data = msg?.data;
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
        this.setState({ invoiceSocketData: data });
      } catch (e) {
        console.error("Error parsing JSON:", e);
      }
    }
  };

  registerInvoices = () => {
    const referenceNumber = this.props.router.match.params.id;
    if (referenceNumber) {
      let socket = registerInvoiceSockets(referenceNumber);
      this.socket = socket;
      if (socket) {
        socket.onmessage = (msg) => {
          this.updateInvoice(msg);
        };
      }
    }
  };

  getInvoices = () => {
    let referenceNumber = this.props?.router?.match?.params?.id;
    if (this.state?.invoiceSocketData) {
      this.setState({ invoiceSocketData: null });
    }
    if (referenceNumber) {
      let api = new API({
        url: `/order-service/invoice?referenceNumber=${referenceNumber}`,
      });
      return api
        .get()
        .then(
          (response) => {
            const invoices = this.getUniqueInvoices(response?.data?.invoices);
            this.setState({ invoices: invoices }, () => {
              if (invoices?.length === 1) {
                this.handleInvoice(invoices?.[0].url);
              }
            });
          },
          (error) => {
            this.setState({
              error: error.message,
            });
            throw error;
          }
        )
        .catch((error) => {
          console.error(error);
        });
    }
  };

  cancelOrder() {
    this.modifyOrder({
      status: "CANCELLED",
    }).then(() => {
      this.setState({ orderCancellationDialog: false });
    });
  }
  showInvoice() {
    if (showConfig()) {
      this.getInvoice();
    } else {
      this.setState({ invoiceShown: true });
    }
  }
  hideInvoice() {
    this.setState({ invoiceShown: false });
  }
  printInvoice() {
    this.setState(
      (prevState) => {
        return {
          invoiceShown: true,
        };
      },
      () => {
        window.print();
        this.hideInvoice();
      }
    );
  }
  metaDataExists() {
    const metaData = this.state.data.metaData;
    if (!metaData) {
      return false;
    } // for null case
    return Array.isArray(metaData)
      ? !metaData
      : !Object.values(metaData).every((val) => !val); // for empty array and object case
  }
  isMetaDataKeySet() {
    if (isExtensionEnabled("EntityMetaData")) {
      let metaDataExtnId = getExtensionDetails("EntityMetaData").id;
      const api = new API({
        url: `/account-service/extension/${metaDataExtnId}`,
      });
      api
        .get()
        .then((response) => {
          let config = response.data.extension.config.globalConfig.order;
          this.setState({
            orderMeta: config,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    if (isExtensionEnabled("EntityMetaData")) {
      this.addressSequenceApi = new API({
        url: "/config-service/config/customers",
      });
      this.addressSequenceApi
        .get()
        .then((response) => {
          this.setState({
            addressSequence: response.data.customers.addressSequence,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  getCommunicationConfig() {
    let communicationApi = new API({ url: `/account-service/config` });
    communicationApi
      .get()
      .then((response) => {
        this.setState({
          communicationConfig: response.data.config.communication,
          orderConfig: response.data.config.order,
        });
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  getOrderConfig = () => {
    const orderConfigApi = new API({ url: "/config-service/config/order" });
    orderConfigApi
      .get()
      .then((response) => {
        this.setState({ orderConfiguration: response.data?.order || {} });
      })
      .catch((error) => console.error(error));
  };

  async componentDidMount() {
    this.getOrderConfig();
    this.registerInvoices();

    if (isExtensionEnabled("InStoreProcessing")) {
      this.packedOrderEditApi = new API({
        url: `/config-service/config/inStoreProcessing`,
      });
      await this.packedOrderEditApi
        .get()
        .then((res) => {
          if (checkHttpResponseCode(res.code) && res.status === "SUCCESS") {
            const inStoreProcessing = res?.data?.inStoreProcessing;
            this.setState({
              packedOrderEdit: inStoreProcessing?.packedOrderEditAllowed,
              itemAdditionStatus: inStoreProcessing?.itemAdditionStatus,
              itemRemovalStatus: inStoreProcessing?.itemRemovalStatus,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.code === 401 || err.code === 403) {
            throw err;
          }
        });
    }
    this.getData();
    this.isMetaDataKeySet();
    this.getCommunicationConfig();
    window.addEventListener("resize", this.mobileView, false);
  }

  getUniqueInvoices = (invoices) => {
    const invoiceMap = new Map();

    invoices.forEach((invoice) => {
      const invoiceNumber = invoice?.metaData?.invoiceNumber;
      const invoiceCounter = invoice?.metaData?.invoiceCounter;

      if (
        !invoiceMap.has(invoiceNumber) ||
        (invoiceCounter &&
          !invoiceMap.get(invoiceNumber).metaData.invoiceCounter)
      ) {
        invoiceMap.set(invoiceNumber, invoice);
      }
    });
    const response = Array.from(invoiceMap.values());
    return response;
  };

  componentWillUnmount() {
    this.orderDetailsApi && this.orderDetailsApi.cancel();
    this.addressSequenceApi && this.addressSequenceApi.cancel();
    window.removeEventListener("resize", this.mobileView, false);
    if (this.socket) {
      this.socket.close();
    }
  }
  render() {
    let {
      isMobileView,
      isOrderViewItemCollapsed,
      isMetaDataCollapsed,
      isPaymentDetailsCollapsed,
      isRefundDetailsCollapsed,
      data,
      invoiceUrl,
      invoices,
      invoiceSocketData,
      itemAdditionStatus,
      itemRemovalStatus,
    } = this.state;
    const orderType = data?.type?.name;
    const hasAccessToAction = this.state.orderActions;
    const hasOrderEditPermission = hasPermissions("order", "order", "put");
    const url = this.props.url;
    let referenceNumber = this.props.router.match.params.id;
    let view = null;
    let contentLoaded =
      !this.state.loading && !this.state.failed && this.state.data;
    const registerPaymentsLink =
      contentLoaded &&
      `orders/register-payment/${referenceNumber}?pendingAmount=${this.state.data.pendingAmount}&clientId=${this.state.data.clientId}&type=${orderType}`;
    let canBeCompleted = contentLoaded
      ? isExtensionEnabled("InStoreProcessing")
        ? isExtensionEnabled("LogisticsSupport")
          ? this.state.data.status === "DISPATCHED"
          : this.state.data.status === "PACKED"
        : isExtensionEnabled("LogisticsSupport")
        ? this.state.data.status === "DISPATCHED"
        : isExtensionEnabled("ShipRocket")
        ? data.status === "DISPATCHED" &&
          data?.courierDetails?.vendor === "OTHER"
        : !(
            !isExtensionEnabled("InStoreProcessing") &&
            isExtensionEnabled("ShipRocket")
          ) && data.status === "PACKED"
      : false; // Good candidate for using FSM?

    canBeCompleted = canBeCompleted && hasOrderEditPermission;
    if (
      contentLoaded &&
      this.state.data.status === "PACKED" &&
      this.state.data.type &&
      this.state.data.type.name === "PICKUP"
    ) {
      canBeCompleted = true && hasOrderEditPermission;
    }
    let registerPayments =
      contentLoaded &&
      (!isExtensionEnabled("LogisticsSupport") ||
        (this.state.data.type && this.state.data.type.name === "PICKUP")) &&
      this.state.data.paymentStatus === "PENDING";
    const canBeCancelled =
      contentLoaded &&
      this.state.data.status === "PENDING" &&
      this.state.data.status === "RETURNED" &&
      hasOrderEditPermission;
    const canShowTripDetails =
      contentLoaded &&
      isExtensionEnabled("LogisticsSupport") &&
      tripStatus.indexOf(this.state.data.status) > -1;
    const canbeReturned =
      contentLoaded &&
      isExtensionEnabled("OrderReturns") &&
      getReturnableStatuses().indexOf(this.state.data.status) > -1 &&
      hasPermissions("order", "order", "post");
    const canBeDisputed =
      contentLoaded &&
      isExtensionEnabled("OrderDisputes") &&
      this.state.data.status === "COMPLETED";
    const canBePending = contentLoaded && this.state.data.status === "PICKING";
    const showDropdown =
      canBeCompleted ||
      canBeCancelled ||
      canbeReturned ||
      canShowTripDetails ||
      canBeDisputed ||
      canBePending;

    const isStatusPacked =
      (this.state.data && this.state.data.status === "PACKED") || false;
    const packedOrderEdit = this.state.packedOrderEdit || false;
    const fromOperations = this.props.url === "/operations";
    const hasAccessToInvoice =
      this.state?.orderConfiguration?.invoiceSupportEnabled;

    const isStatusComplete =
      this.state.data && invoiceDetailsStatus.includes(this.state.data.status);

    // returnsByRE is an array of returned product
    let productReturned = false;
    if (data) {
      const { returnsByRE = [] } = data;
      productReturned = returnsByRE.length > 0;
    }
    const hideOptionForSeller =
      !isExtensionEnabled("MarketPlace") || isMarketPlaceOwner();

    return (
      <AuthenticatedPage
        storeDependent
        className="order-details"
        menu={this.props.menu}
      >
        <Dialog
          show={this.state.orderCancellationDialog}
          title={getMessage("order.details.cancellation.title")}
          information={getMessage("order.details.cancellation.message")}
          onOk={this.cancelOrder}
          close={this.toggleCancellationDialog}
          okText={getMessage("order.details.cancellation.confirmText")}
          closeText={getMessage("order.details.cancellation.cancelText")}
        />
        <Dialog
          show={this.state.orderCompletionDialog}
          title={getMessage("order.details.completion.title")}
          information={
            productReturned
              ? getMessage("order.details.completion.item.return.message")
              : getMessage("order.details.completion.message")
          }
          onOk={this.completeOrder}
          close={this.toggleCompletionDialog}
          okText={
            productReturned
              ? getMessage("order.details.completion.confirmReturnItem")
              : getMessage("order.details.completion.confirmText")
          }
          closeText={getMessage("order.details.completion.cancelText")}
        />
        <Dialog
          show={this.state.moveToPendingDialog}
          title={getMessage("order.details.cancellation.title")}
          information={getMessage("order.details.pending.message")}
          onOk={this.moveToPending}
          close={this.toggleMoveToPendingDialog}
          okText={getMessage("order.details.cancellation.confirmText")}
          closeText={getMessage("order.details.cancellation.cancelText")}
        />
        <div className="flex-around">
          <h1 className="title heading">
            {getMessage("order.details.heading")}
            {this.state.data &&
              (this.state.data.clientId
                ? getMessage("order.table.clientId.prefix")
                : getMessage("order.table.referenceNumber.prefix"))}
            <strong className="reference-number">
              {this.state.data && (this.state.data.clientId || referenceNumber)}
            </strong>
          </h1>
          {contentLoaded ? (
            <div className="order-actions">
              <div className="order-actions-mobile-view">
                <DropDown
                  className="order-actions-dropdown"
                  icon={<img src={ICONS.VELLIP} alt="⋮" />}
                >
                  {hasAccessToInvoice && (
                    <>
                      {!showConfig() && (
                        <DropDownItem>
                          <button
                            className="invoice-button print-icon"
                            onClick={this.printInvoice}
                          >
                            {getMessage(
                              "order.details.invoice.printButtonText"
                            )}
                          </button>
                        </DropDownItem>
                      )}

                      <DropDownItem>
                        <button
                          className="invoice-button"
                          onClick={this.showInvoice}
                        >
                          {getMessage("order.details.invoice.buttonText")}
                        </button>
                      </DropDownItem>
                    </>
                  )}
                  <DropDownItem>
                    <button className="invoice-button">
                      <Link to={`${url}/orders/order-log/${referenceNumber}`}>
                        {getMessage("order.actions.logs")}
                      </Link>
                    </button>
                  </DropDownItem>
                </DropDown>
              </div>
              <div className="order-actions-web-view">
                {hasAccessToInvoice && (
                  <>
                    {!showConfig() ? (
                      <>
                        <button
                          className="invoice-button print-icon"
                          onClick={this.printInvoice}
                        >
                          {getMessage("order.details.invoice.printButtonText")}
                        </button>
                        <button
                          className="invoice-button"
                          onClick={this.showInvoice}
                        >
                          {getMessage("order.details.invoice.buttonText")}
                        </button>
                      </>
                    ) : (
                      <>
                        {invoiceSocketData?.referenceNumber && (
                          <span className="invoice-indicator">
                            {getMessage("order.details.invoice.indicator")}
                          </span>
                        )}
                        {invoices?.length > 1 && (
                          <span className="dropdown-icon">▼</span>
                        )}
                        {isStatusComplete && (
                          <>
                            <DropDown
                              className={
                                invoices?.length > 1
                                  ? "order-action-invoice-dropdown invoice-button invoice-button-dropdown"
                                  : "order-invoice-dropdown invoice-button invoice-button-dropdown"
                              }
                              icon={"Invoice"}
                              onClick={this.getInvoices}
                            >
                              {invoices &&
                                invoices?.length > 1 &&
                                invoices?.map((data, index) => {
                                  const { metaData, url } = data;
                                  const { invoiceNumber = "" } = metaData || {};

                                  if (invoiceNumber.includes("R")) {
                                    const tag = invoiceNumber?.indexOf("R");
                                    return (
                                      <DropDownItem
                                        key={index}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.handleInvoice(url);
                                        }}
                                      >
                                        {getMessage(
                                          "order.details.return.optionText"
                                        )}{" "}
                                        {invoices?.length > 2 &&
                                          invoiceNumber?.substring(tag + 1)}
                                      </DropDownItem>
                                    );
                                  }
                                  return (
                                    <DropDownItem
                                      key={index}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.handleInvoice(url);
                                      }}
                                    >
                                      {getMessage(
                                        "order.details.original.optionText"
                                      )}
                                    </DropDownItem>
                                  );
                                })}
                            </DropDown>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <button className="invoice-button">
                  <Link to={`${url}/orders/order-log/${referenceNumber}`}>
                    {getMessage("order.actions.logs")}
                  </Link>
                </button>
              </div>
              {showDropdown && (
                <DropDown
                  className="order-actions-dropdown"
                  icon={<img src={ICONS.VELLIP} alt="⋮" />}
                >
                  {canBeCompleted &&
                    hasAccessToAction?.includes("COMPLETE_ORDER") &&
                    (!registerPayments ? (
                      <DropDownItem onClick={this.toggleCompletionDialog}>
                        {getMessage("order.details.complete.optionText")}
                      </DropDownItem>
                    ) : (
                      <DropDownItem>
                        <Link
                          to={
                            url
                              ? `${url}/${registerPaymentsLink}`
                              : `/operations/${registerPaymentsLink}`
                          }
                        >
                          {getMessage("order.details.complete.optionText")}
                        </Link>
                      </DropDownItem>
                    ))}
                  {hideOptionForSeller &&
                    canbeReturned &&
                    hasAccessToAction?.includes("RETURN_ORDER") && (
                      <DropDownItem>
                        <Link
                          to={`${url}/orders/file-for-return/${referenceNumber}`}
                        >
                          {getMessage("order.details.returm.optionText")}
                        </Link>
                      </DropDownItem>
                    )}
                  {canBeDisputed && (
                    <DropDownItem>
                      <Link
                        to={`${url}/orders/dispute/${this.state.data.referenceNumber}`}
                      >
                        {getMessage("order.details.dispute.optionText")}
                      </Link>
                    </DropDownItem>
                  )}
                  {hideOptionForSeller &&
                    canBeCancelled &&
                    hasAccessToAction?.includes("CANCEL_ORDER") && (
                      <DropDownItem onClick={this.toggleCancellationDialog}>
                        {getMessage("order.details.cancel.optionText")}
                      </DropDownItem>
                    )}
                  {hideOptionForSeller && canShowTripDetails && (
                    <DropDownItem>
                      <Link
                        to={`/logistics/trips?orderNumber=${referenceNumber}`}
                      >
                        {getMessage("order.actions.trips")}
                      </Link>
                    </DropDownItem>
                  )}
                  {canBePending && (
                    <DropDownItem onClick={this.toggleMoveToPendingDialog}>
                      {getMessage("order.action.moveToPending")}
                    </DropDownItem>
                  )}
                  {hideOptionForSeller &&
                    isStatusPacked &&
                    packedOrderEdit &&
                    fromOperations &&
                    hasAccessToAction?.includes("EDIT_ORDER") && (
                      <DropDownItem>
                        <Link to={`edit-packed/${referenceNumber}`}>
                          {getMessage("order.action.editPackedOrder")}
                        </Link>
                      </DropDownItem>
                    )}
                </DropDown>
              )}
            </div>
          ) : null}
        </div>
        {view}
        {this.state.loading ? <Loader /> : null}
        {this.state.failed ? (
          <div className="retry-message text-muted" onClick={this.getData}>
            {getMessage("order.details.errors.onLoad")}
          </div>
        ) : null}
        {contentLoaded ? (
          <div>
            {invoiceUrl && this.state.invoiceShown ? (
              <PDFViewer
                src={invoiceUrl}
                show={this.state.invoiceShown}
                hideInvoice={() => this.setState({ invoiceShown: false })}
                handlePrint={this.handlePrint}
              />
            ) : (
              <OrderInvoice
                referenceNumber={referenceNumber}
                show={this.state.invoiceShown}
                close={() => this.setState({ invoiceShown: false })}
                data={this.state.data}
                addressSequence={this.state.addressSequence}
              />
            )}
            <div className="order-summary-wrapper">
              <div className="user-details">
                <PlacementDetails
                  data={{
                    preorder: this.state.data.preorder,
                    status: this.state.data.status,
                    creationTime: this.state.data.createdAt,
                    completionTime: this.state.data.completedAt,
                    device: this.state.data.placedFrom,
                  }}
                />
                <DeliveryDetails
                  data={{
                    customer: this.state.data.customer,
                    address: this.state.data.address,
                    billingAddress: this.state.data.billingAddress,
                    pickupLocation: this.state.data.pickupLocation,
                    status: this.state.data.status,
                    preferredSlot: {
                      startTime: this.state.data.slotStartTime,
                      endTime: this.state.data.slotEndTime,
                      type: this.state.data.slotType,
                    },
                    preferredDate: this.state.data.preferredDate,
                    storeId: this.state.data.store && this.state.data.store.id,
                    slotType: this.state.data.slotType,
                    url: url,
                    metaData: this.state.data.metaData,
                    addressSequence: this.state.addressSequence,
                    type: this.state.data.type && this.state.data.type.name,
                  }}
                  editable={
                    deliveryDetailsEditableStatus.indexOf(
                      this.state.data.status
                    ) > -1 &&
                    hasOrderEditPermission &&
                    hasAccessToAction?.includes("EDIT_ORDER")
                  }
                  onChange={this.handleAddressChange}
                  error={this.state.error}
                  communicationConfig={this.state.communicationConfig}
                  orderConfig={this.state.orderConfig}
                  org={this.organization}
                  referenceNumber={this.props.router.match.params.id}
                />
              </div>
              {isMobileView ? (
                <div
                  className={`collapse-container-order ${isOrderViewItemCollapsed}`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isOrderViewItemCollapsed:
                          !this.state.isOrderViewItemCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isOrderViewItemCollapsed ? "+" : "-"}
                  </button>
                  {isOrderViewItemCollapsed && (
                    <h3>
                      {getMessage("order.details.itemsTable.title")}
                      <span className="text-muted">
                        ({(this.state.data.items || []).length})
                      </span>
                    </h3>
                  )}
                </div>
              ) : (
                ""
              )}
              {(!isMobileView ||
                (isMobileView && !isOrderViewItemCollapsed)) && (
                <OrderItemsView
                  data={{
                    items: this.state.data.items || [],
                    payment: this.state.data.payment || [],
                    shipping: this.state.data.shipping,
                    clickAndCollectCharges:
                      this.state.data.clickAndCollectCharges,
                    type: this.state.data.type.name,
                    amount: this.state.data.amount,
                    discount: this.state.data.discount,
                    couponDiscount: this.state.data.couponDiscount,
                    refundAmount: this.state.data.refundAmount,
                    pendingAmount: this.state.data.pendingAmount,
                    invoiceAmount: this.state.data.invoiceAmount,
                    surcharge: this.state.data.surcharge,
                    orderDiscount: this.state.data.orderDiscount,
                  }}
                  editable={
                    ["PENDING", "PICKING"].includes(this.state.data.status) &&
                    hasOrderEditPermission &&
                    hasAccessToAction?.includes("EDIT_ORDER")
                  }
                  currency={this.currency}
                  onChange={this.handleItemEdit}
                  status={this.state.data.status}
                  storeId={this.state.data.store && this.state.data.store.id}
                  editableList={{
                    isItemAddition: itemAdditionStatus,
                    isItemRemoval: itemRemovalStatus,
                  }}
                />
              )}
              {isMobileView ? (
                <div
                  className={`collapse-container-metadata ${isMetaDataCollapsed}`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isMetaDataCollapsed: !this.state.isMetaDataCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isMetaDataCollapsed ? "+" : "-"}
                  </button>
                  {isMetaDataCollapsed && (
                    <h3>{getMessage("Custom Fields Details")}</h3>
                  )}
                </div>
              ) : (
                ""
              )}
              {(!isMobileView || (isMobileView && !isMetaDataCollapsed)) &&
                isExtensionEnabled("EntityMetaData") &&
                (this.state.data.status === "COMPLETED" ||
                this.state.data.status === "CANCELLED"
                  ? this.metaDataExists()
                  : true) &&
                !Array.isArray(this.state.orderMeta) && (
                  <MetaDataDetails
                    data={this.state.data.metaData}
                    editable={
                      this.state.data.status !== "COMPLETED" &&
                      this.state.data.status !== "CANCELLED" &&
                      hasOrderEditPermission &&
                      hasAccessToAction?.includes("EDIT_CUSTOM_FIELDS")
                    }
                    onChange={this.handleMetaDataEdit}
                  />
                )}
              {isMobileView ? (
                <div
                  className={`collapse-container-payment ${isPaymentDetailsCollapsed}`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isPaymentDetailsCollapsed:
                          !this.state.isPaymentDetailsCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isPaymentDetailsCollapsed ? "+" : "-"}
                  </button>
                  {isPaymentDetailsCollapsed && (
                    <h3>{getMessage("order.details.payment.heading")}</h3>
                  )}
                </div>
              ) : (
                ""
              )}
              {(!isMobileView ||
                (isMobileView && !isPaymentDetailsCollapsed)) &&
                this.state.data.payment &&
                this.state.data.payment.length > 0 && (
                  <PaymentDetails
                    payment={this.state.data.payment}
                    currency={this.currency}
                  />
                )}
              {isMobileView ? (
                <div
                  className={`collapse-container-refund ${isRefundDetailsCollapsed}`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        isRefundDetailsCollapsed:
                          !this.state.isRefundDetailsCollapsed,
                      })
                    }
                    className="component-collapse"
                  >
                    {isRefundDetailsCollapsed ? "+" : "-"}
                  </button>
                  {isRefundDetailsCollapsed && (
                    <h3>{getMessage("Refund Details")}</h3>
                  )}
                </div>
              ) : (
                ""
              )}
              {(!isMobileView || (isMobileView && !isRefundDetailsCollapsed)) &&
                this.state.data.refund &&
                this.state.data.refund.length > 0 && (
                  <RefundDetails
                    refund={this.state.data.refund}
                    currency={this.currency}
                  />
                )}
              {this.state.data.packingDetails &&
                this.state.data.packingDetails.length > 0 && (
                  <PackingDetails
                    packingDetails={this.state.data.packingDetails[0]?.details}
                    packageMetaData={
                      this.state.data.packingDetails[0]?.metaData
                    }
                    editable={
                      hasOrderEditPermission &&
                      hasAccessToAction?.includes("EDIT_ORDER")
                    }
                    referenceNumber={referenceNumber}
                    history={this.props.history}
                    status={this.state.data.status}
                    courierStatus={this.state.data?.courierDetails?.status}
                  />
                )}
            </div>
          </div>
        ) : null}
      </AuthenticatedPage>
    );
  }
}
