import React, { Component } from "react";
import Image from "../../../../../components/Image";
import Table, { Header, Row, Cell } from "../../../../../components/Table";
import { getMessage } from "../../../../../lib/translator";
import { getPaymentLogo, formatCardNumber } from "../../../../../lib/payment";
import { is_Domain_WSI } from "../../../../../utils/domainCheck";
function getMobileView() {
  return window.screen.width <= 480;
}

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: getMobileView(),
    };
    this.mobileView = this.mobileView.bind(this);
  }

  mobileView() {
    this.setState({ isMobileView: getMobileView() });
  }

  componentDidMount() {
    window.addEventListener("resize", this.mobileView, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.mobileView, false);
  }
  render() {
    let { isMobileView } = this.state;
    return (
      <div className="payment-details">
        <div className="section-title">
          <h3>{getMessage("order.details.payment.heading")}</h3>
          <Table>
            <Header>
              <Cell>{getMessage("order.details.payment.amount")}</Cell>
              <Cell>{getMessage("order.details.payment.mode")}</Cell>
              <Cell>{getMessage("order.details.payment.card")}</Cell>
              <Cell>{getMessage("order.details.payment.status")}</Cell>
              <Cell>{getMessage("order.details.payment.details")}</Cell>
            </Header>
            {this.props.payment.map((item, i) => (
              <Row key={`payment-${i}`}>
                <Cell className="payment-amount">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.amount")}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.props.currency.symbol} {item.amount}
                </Cell>
                <Cell className="payment-mode">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.mode")}
                    </div>
                  ) : (
                    ""
                  )}
                  {item?.mode === "COD" && is_Domain_WSI() ? (
                    <div>{getMessage("cop")}</div>
                  ) : (
                    <div>{item.mode}</div>
                  )}
                </Cell>
                <Cell className="payment-card-number">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.card")}
                    </div>
                  ) : (
                    ""
                  )}
                  {item.metaData &&
                  (item.metaData.cardNumber || item.metaData.cardType) ? (
                    <React.Fragment>
                      <Image
                        className="payment-card"
                        size="sm"
                        src={getPaymentLogo(item.metaData.cardType)}
                      />
                      <span>
                        {formatCardNumber(
                          item.metaData.cardNumber,
                          item.metaData.type
                        )}
                      </span>
                    </React.Fragment>
                  ) : (
                    "-"
                  )}
                </Cell>
                <Cell className="payment-status">
                  {isMobileView ? (
                    <div className="text-muted">
                      {getMessage("order.details.payment.status")}
                    </div>
                  ) : (
                    ""
                  )}
                  {item.status}
                </Cell>

                <Cell className="payment-transaction-id">
                  {item?.mode !== "COD" && (
                    <>
                      {isMobileView ? (
                        <div className="text-muted">
                          {getMessage("order.details.payment.transactionId")}
                        </div>
                      ) : (
                        ""
                      )}
                      <span className="transactionId">
                        {getMessage("order.details.payment.transactionId")}{" "}
                        &ndash;{" "}
                        {(item.metaData && item.metaData.transaction_id) ||
                          item.transactionId}
                      </span>
                    </>
                  )}
                </Cell>
              </Row>
            ))}
          </Table>
        </div>
      </div>
    );
  }
}

export default PaymentDetails;
