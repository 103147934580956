import React from "react";
import {
  BaseForm,
  Upload,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { Dialog } from "../../../../../components/Popup";
import { getMessage } from "../../../../../lib/translator";
import { get } from "../../../../../lib/storage";
import API from "../../../../../lib/api";
import MOBILE2 from "./Mobile2.svg";
import { faro } from "@grafana/faro-web-sdk";

const resolutionsAndroid = [
  //andriod
  { width: 200, height: 320 },
  { width: 320, height: 480 },
  { width: 480, height: 800 },
  { width: 720, height: 1280 },
  { width: 960, height: 1600 },
  { width: 1280, height: 1920 },
];

// ios
const resolutionsIos = [
  { width: 640, height: 1136 },
  { width: 750, height: 1334 },
  { width: 828, height: 1792 },
  { width: 1125, height: 2436 },
  { width: 1242, height: 2208 },
  { width: 1242, height: 2688 },
  { width: 1536, height: 2048 },
  { width: 1668, height: 2224 },
  { width: 2048, height: 2732 },
];

let organization = get("organization");

class AndroidForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      values: Object.assign(
        {},
        {
          ...this.getConfigKeysValues(this.props.configData),
          zoppingAccountSelect: true,
          appName: organization.name,
        }
      ),
      submitting: false,
      packageNameExist: !!this.props.configData["package"]?.value,
    };
  }

  getConfigKeysValues = (config) => {
    let configKeysValues = {};
    for (let key in config) {
      configKeysValues[key] = config[key];
    }
    return configKeysValues;
  };

  onSubmit(data) {
    const { iosAppConfig, androidAppConfig } = this.mappingPayload(data);

    const api = new API({
      url: "/config-service/config",
    });
    this.setState({ submitting: true });
    api
      .post({ androidAppConfig, iosAppConfig })
      .then((data) => {
        this.setState({
          submitting: false,
          showSuccessDialog: true,
        });
      })
      .catch((err) => {
        if (err.message) {
          this.setState({
            submitting: false,
          });
          if (faro?.api?.pushError) {
            faro.api.pushError(err);
          }
        } else {
          console.error(err);
          this.setState({
            submitting: false,
          });
          throw err;
        }
      });
  }

  mappingPayload(data) {
    const androidAppConfig = {};
    const iosAppConfig = {};
    const getAndriodAppConfig = () => {
      const splashResolutionsAndroid = {};
      let splash = data?.splashMdpi?.imageUpload?.resizedUrls;
      for (let key in splash) {
        const mappingOne = {
          "200x320": "Ldpi",
          "320x480": "Mdpi",
          "480x800": "Hdpi",
          "720x1280": "Xhdpi",
          "960x1600": "Xxhdpi",
          "1280x1920": "Xxxhdpi",
        };
        const resolution = mappingOne?.[key];

        if (resolution) {
          splashResolutionsAndroid[`splash${resolution}`] = splash?.[key]?.[0]; // Assign to Android
        }
      }
      Object.assign(androidAppConfig, splashResolutionsAndroid);
      return androidAppConfig;
    };

    const getIosAppConfig = () => {
      const splashResolutionsIOS = {};
      let splashIOS = data?.splashMdpi?.imageUpload?.resizedUrls;
      for (let key in splashIOS) {
        const mappingTwo = {
          "640x1136": "640x1136",
          "750x1334": "750x1334",
          "828x1792": "828x1792",
          "1125x2436": "1125x2436",
          "1242x2208": "1242x2208",
          "1242x2688": "1242x2688",
          "1536x2048": "1536x2048",
          "1668x2224": "1668x2224",
          "2048x2732": "2048x2732",
        };
        const resolution = mappingTwo?.[key];
        if (resolution) {
          splashResolutionsIOS[`splash${key}`] = splashIOS?.[key]?.[0]; // Assign to Ios
        }
      }
      Object.assign(iosAppConfig, splashResolutionsIOS);
      return iosAppConfig;
    };

    return {
      androidAppConfig: getAndriodAppConfig(),
      iosAppConfig: getIosAppConfig(),
    };
  }

  closeDialogs = () => {
    this.setState({ showSuccessDialog: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.configData !== this.props.configData) {
      this.setState({
        values: Object.assign(
          {},
          this.getConfigKeysValues(this.props.configData),
          { zoppingAccountSelect: true, appName: organization.name }
        ),
        packageNameExist: !!this.props.configData["package"]?.value,
      });
    }
  }

  render() {
    const { SubmitButton } = this.buttons;
    const { Form } = this.components;
    return (
      <>
        <Form className="splash-form">
          <div className="icon-upload-container">
            <div className="form-container-child">
              <div className="form-fields">
                <Upload
                  className="app-splash-screen"
                  label={getMessage("mobileApps.splashScreen.heading")}
                  name="appIcon"
                  resolutions={[resolutionsAndroid, resolutionsIos]}
                  {...this.generateStateMappers({
                    stateKeys: ["splashMdpi"],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                  })}
                  placeholder="Upload Splash Screen"
                  required
                  validationStrings={{
                    valueMissing: getMessage("input.requiredMessage"),
                    fileSizeExeeded: getMessage("input.fileSizeExeeded"),
                    invalidFileType: getMessage("input.invalidFileType"),
                  }}
                />
                <div className="label">
                  {getMessage("mobileApps.longDescription.suggestion")}
                </div>
              </div>
            </div>
            <div className="image-container-child">
              <div className="inner-image-container">
                <img src={MOBILE2} alt="" width="144px" height="294px" />
                <div className="splash-notch-container" />
                <div className="splash-container-child">
                  <img
                    className="splash-image-border"
                    alt=""
                    src={
                      this.state.values?.splashMdpi?.imageUpload?.url ??
                      this.state.values?.splashXxxhdpi
                    }
                    width="128px"
                    height="278px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="splash-submit-button">
            <SubmitButton onClick={this.onSubmit}>{"Save"}</SubmitButton>
          </div>
        </Form>
        <Dialog
          show={this.state.showSuccessDialog}
          className="success"
          information={getMessage("basicInfo.save.success.title")}
          close={this.closeDialogs}
          closeText={getMessage("notification.okay.text")}
        />
      </>
    );
  }
}

export default AndroidForm;
