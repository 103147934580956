import React from "react";
import {
  BaseForm,
  Input,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import HidePassword from "../../../../../components/Form/icons/HidePassword";
import ShowPassword from "../../../../../components/Form/icons/ShowPassword";
import "./style.css";

class ResetPassword extends BaseForm {
  constructor(props) {
    super(props);
    this.state.submitting = false;
    this.state.showNewPassword = false;
    this.state.showConfirmPassword = false;
  }

  onSubmit(formData) {
    let data = Object.assign({}, formData);
    const { newPassword, confirmPassword } = data;

    // new pass and confirm pass validation
    if (newPassword !== confirmPassword) {
      this.setState({
        formError: getMessage("shifts.passwords.dont.match"),
      });
      return;
    }

    // Clear error if passwords match
    this.setState({
      formError: null,
      submitting: true,
    });

    const api = new API({
      url: `/account-service/employee/${this.props.id}/password`,
    });
    api
      .put(data)
      .then((response) => this.props.onSuccess())
      .catch((error) => {
        console.error(error);
        this.setState({
          formError: error.message,
          submitting: false,
        });
      });
  }

  handleShowPassword = (e, key) => {
    e.preventDefault();
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    const { Form } = this.components;
    const { SubmitButton } = this.buttons;
    const { showNewPassword, showConfirmPassword } = this.state;
    const NewPasswordIcon = showNewPassword ? ShowPassword : HidePassword;
    const ConfirmPasswordIcon = showConfirmPassword
      ? ShowPassword
      : HidePassword;

    return (
      <Form>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        <div className="password-wrp">
          <Input
            label={getMessage("shifts.newPassword")}
            placeholder={getMessage("shifts.newPassword.placeholder")}
            name="new-password"
            required
            type={showNewPassword ? "text" : "password"}
            {...this.generateStateMappers({
              stateKeys: ["newPassword"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <div
            className="password-view"
            onClick={(e) => this.handleShowPassword(e, "showNewPassword")}
          >
            <NewPasswordIcon />
          </div>
        </div>
        <div className="password-wrp">
          <Input
            label={getMessage("shifts.confirmpassword")}
            placeholder={getMessage("shifts.confirmpassword.placeholder")}
            name="confirm-password"
            required
            type={showConfirmPassword ? "text" : "password"}
            {...this.generateStateMappers({
              stateKeys: ["confirmPassword"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <div
            className="password-view"
            onClick={(e) => this.handleShowPassword(e, "showConfirmPassword")}
          >
            <ConfirmPasswordIcon />
          </div>
        </div>
        <div className="form-action">
          <SubmitButton disabled={this.state.submitting}>
            {getMessage("shifts.form.submitText")}
          </SubmitButton>
          <button
            type="button"
            className="button"
            onClick={() => this.props.close()}
          >
            {getMessage("shifts.form.cancel")}
          </button>
        </div>
      </Form>
    );
  }
}

export default ResetPassword;
