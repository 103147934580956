import React from "react";
import {
  BaseForm,
  Radio,
  Searchable,
  VALIDATION_TYPES,
  Input,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

// import "./style.css";

export default class UserFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;

    return (
      <Form className="seller-user-filter-form">
        <div className="form-fields">
          <Input
            label={getMessage("seller.filters.sellerName.heading")}
            placeholder={getMessage("user.filters.email.placeholder")}
            name="email"
            type="email"
            {...this.generateStateMappers({
              stateKeys: ["email"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Input
            label={getMessage("brand.filters.name.heading")}
            placeholder={getMessage("user.filters.name.placeholder")}
            name="name"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["name"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Searchable
            label={getMessage("seller.filters.seller.name.heading")}
            placeholder={getMessage("user.filters.seller.placeholder")}
            name="seller"
            searchUrl="/account-service/seller"
            valueKey="id"
            responseKey="seller"
            nameKey="name"
            searchKey="name"
            transformResponse={(response) => response?.data?.seller}
            {...this.generateStateMappers({
              stateKeys: ["seller"],
            })}
          />
          <Radio
            className="product-status"
            label={getMessage("product.filters.status.heading")}
            name="status"
            options={[
              {
                text: getMessage("product.filters.status.label.all"),
                value: "",
              },
              {
                text: getMessage("product.filters.status.label.enabled"),
                value: "ACTIVE",
              },
              {
                text: getMessage("product.filters.status.label.disabled"),
                value: "INACTIVE",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("product.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("product.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
