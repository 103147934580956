import React, { Component } from "react";
import img from "./gridBlank.png";

export default class extends Component {
  render() {
    return (
      <div className="GridCollection">
        <div>
          <img src={img} alt="" />
        </div>
      </div>
    );
  }
}
