import React from "react";
import {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
  Checkbox,
} from "../../../../../components/Form";
import { isExtensionEnabled } from "../../../../../lib/auth";
import { getMessage } from "../../../../../lib/translator";
import API from "../../../../../lib/api";
import {
  getMinutes,
  getStandradDateTime,
  getPrintableTime,
  utcToLocalTime,
} from "../../../../../lib/datetime";
import {
  sortSlots,
  getSelectOrderStatus,
} from "../../../../../lib/commonlyused";
import { is_Domain_WSI } from "../../../../../utils/domainCheck";

const paymentModes = [
  {
    text: is_Domain_WSI() ? getMessage("cop") : getMessage("order.payment.cod"),
    value: "COD",
  },
  {
    text: getMessage("order.payment.online"),
    value: "ONLINE",
  },
];

const paymentStatuses = [
  {
    text: getMessage("order.payment.pending"),
    value: "PENDING",
  },
  {
    text: getMessage("order.payment.paid"),
    value: "PAID",
  },
  {
    text: getMessage("order.payment.refund"),
    value: "REFUND",
  },
];

export default class OrderFiltersForm extends BaseForm {
  constructor(props) {
    // lastOrderType is being used to track the change in selected orderType
    // As the state is getting mutated, we are not able to get correct prevState
    super(props);
    this.lastOrderType = undefined;
    this.getOrderSlots = this.getOrderSlots.bind(this);
  }

  getOrderSlots(orderType) {
    this.slotApi = new API({ url: `/order-service/slot` });
    let params = { paginated: "false" };
    if (orderType) {
      params = { ...params, orderType };
    }
    this.setState({ isFetchingSlots: true }, () => {
      this.slotApi
        .get(params)
        .then((response) => {
          let slots = response.data?.slot || [];
          slots = sortSlots(slots);
          let slotOptions = [];
          slots.forEach((slot) => {
            if (slot.type === "STANDARD") {
              slotOptions.push({
                text: `${
                  getPrintableTime(
                    getStandradDateTime(
                      utcToLocalTime,
                      `${new Date().toISOString().split("T")[0]} ${
                        slot.startTime
                      }`
                    )
                  ).split(",")[1]
                } - ${
                  getPrintableTime(
                    getStandradDateTime(
                      utcToLocalTime,
                      `${new Date().toISOString().split("T")[0]} ${
                        slot.endTime
                      }`
                    )
                  ).split(",")[1]
                }`,
                value: JSON.stringify({
                  startTime: slot.startTime,
                  endTime: slot.endTime,
                  type: slot.type,
                }),
              });
            } else if (slot.type === "ASAP") {
              slotOptions.push({
                text:
                  getMessage("slots.asap") +
                  " " +
                  getMinutes(slot.endTime) +
                  " " +
                  getMessage("slots.asap.minute"),
                value: JSON.stringify({
                  endTime: slot.endTime,
                  type: slot.type,
                }),
              });
            }
          });
          this.setState({
            slotOptions,
            isFetchingSlots: false,
            slotFetchError: undefined,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  componentDidMount() {
    this.configApi = new API({ url: "/account-service/config/order" });
    this.configApi
      .get()
      .then((response) => {
        let orderTypes = response.data.config.order.orderTypes;
        orderTypes = (orderTypes || []).map((orderType) => {
          return {
            text:
              orderType.split("")[0] +
              orderType
                .toLowerCase()
                .split("")
                .splice(1, orderType.length)
                .join(""),
            value: orderType,
          };
        });
        this.setState({ orderTypes });
      })
      .catch((error) => {
        console.error(error);
      });
    if (isExtensionEnabled("DeliverySlots")) {
      this.getOrderSlots();
    }
  }

  // This componentDidUpdate method used to track the change in orderType
  // As inside BaseForm the state is getting mutated, we are not able to compare
  // prevState and current state. This is why lastOrderType variable is introduced
  // to store the last value of orderType. As it does not have any impact on the UI,
  // we are not storing it inside state, thus we can prevent unnecessary render.
  componentDidUpdate(prevProps, prevState) {
    const { values } = this.state;
    if (values && values.orderType !== this.lastOrderType) {
      this.lastOrderType = values.orderType;
      this.getOrderSlots(values.orderType);
    }
  }

  componentWillUnmount() {
    this.slotApi && this.slotApi.cancel();
  }

  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    const { slotOptions, orderTypes, isFetchingSlots } = this.state;
    return (
      <Form>
        <div className="form-fields">
          <Input
            label={getMessage("order.filters.referenceNumber.heading")}
            placeholder={getMessage(
              "order.filters.referenceNumber.placeholder"
            )}
            name="referenceNumber"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["referenceNumber"],
              loseEmphasisOnFill: true,
            })}
          />
          <Input
            label={getMessage("order.filters.clientId.heading")}
            placeholder={getMessage("order.filters.clientId.placeholder")}
            name="clientId"
            type="text"
            {...this.generateStateMappers({
              stateKeys: ["clientId"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage("order.filters.status.heading")}
            placeholder={getMessage("order.filters.status.placeholder")}
            name="status"
            options={getSelectOrderStatus()}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              loseEmphasisOnFill: true,
            })}
          />
          {isExtensionEnabled("OnlinePaymentSupport") && (
            <Select
              label={getMessage("order.filters.payment.heading")}
              placeholder={getMessage("order.filters.payment.placeholder")}
              name="paymentMode"
              options={paymentModes}
              {...this.generateStateMappers({
                stateKeys: ["paymentMode"],
                loseEmphasisOnFill: true,
              })}
            />
          )}
          <Select
            label={getMessage("order.filters.paymentStatus")}
            placeholder={getMessage("order.filters.paymentStatus.placeholder")}
            name="paymentStatus"
            options={paymentStatuses}
            {...this.generateStateMappers({
              stateKeys: ["paymentStatus"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage("order.filters.orderType")}
            placeholder={getMessage("order.filters.orderType.placeholder")}
            name="orderType"
            options={orderTypes}
            {...this.generateStateMappers({
              stateKeys: ["orderType"],
              loseEmphasisOnFill: true,
            })}
          />
          {slotOptions && (
            <Select
              label={getMessage("order.filters.slot")}
              placeholder={getMessage("order.filters.slot.placeholder")}
              name="orderSlot"
              options={slotOptions}
              {...this.generateStateMappers({
                stateKeys: ["slot"],
                loseEmphasisOnFill: true,
              })}
              disabled={isFetchingSlots}
              title={
                isFetchingSlots
                  ? getMessage("order.filters.slot.fetchTitle")
                  : undefined
              }
            />
          )}
          <SingleDatePicker
            label={getMessage("order.filters.placedOn")}
            placeholder={getMessage("order.filter.placedOn.placeholder")}
            name="placed-on"
            isOutsideRange
            {...this.generateStateMappers({
              stateKeys: ["placedOn"],
              loseEmphasisOnFill: true,
            })}
          />
          <SingleDatePicker
            label={getMessage("order.filters.preferredDate")}
            placeholder={getMessage("order.filter.placedOn.placeholder")}
            name="preferred-date"
            allowAllDates
            {...this.generateStateMappers({
              stateKeys: ["preferredDate"],
              loseEmphasisOnFill: true,
            })}
          />
          {isExtensionEnabled("PreOrderSupport") && (
            <Checkbox
              label={getMessage("order.filters.preorder")}
              name={`preorder`}
              className="pre-order"
              {...this.generateStateMappers({
                stateKeys: ["preorder"],
                loseEmphasisOnFill: true,
              })}
              controlled
            />
          )}
        </div>
        <SubmitButton>{getMessage("order.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("order.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
