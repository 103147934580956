import React, { Component, useEffect, useState } from "react";
import { DropDownItem, ICONS, DropDown } from "../../../components/DropDown";
import { getMessage } from "../../../lib/translator";
import { Link } from "react-router-dom";
import emptyIcon from "./seller-empty.svg";
import Image from "../../../components/Image";

import ListingPageWithRoutes from "../../../containers/ListingPage/listingRouter";

import SellerForm from "./Form";
import { Row, Cell } from "../../../components/Table";
import "./style.css";
import { TABLE_ACTIONS } from "../../../containers/ListingPage";
import userImage from "../userImage.svg";
import { Popup } from "../../../components/Popup";
import SellerUpload from "./SellersUpload";
import SellerFiltersForm from "./Filters";

const getStatus = (status) => {
  return status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
};

class SellerActions extends Component {
  render() {
    const { id, status, onAction } = this.props;
    const action = getStatus(status);
    return (
      <div>
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem>
            <Link to={`/sellers/seller/edit/${id}`}>
              {getMessage("seller.status.edit")}
            </Link>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.DELETE, { id });
            }}
          >
            {getMessage("seller.status.delete")}
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              onAction(TABLE_ACTIONS.UPDATE, { id }, { status: action });
            }}
          >
            {status === "ACTIVE"
              ? getMessage("seller.status.inactive")
              : getMessage("seller.status.active")}
          </DropDownItem>
        </DropDown>
      </div>
    );
  }
}

const tableProperties = (isMobileView) => {
  return {
    headers: [
      getMessage("sellers.seller.table.action.name"),
      getMessage("sellers.seller.table.action.contact"),
      getMessage("sellers.seller.table.action.address"),
      getMessage("sellers.seller.table.action.status"),
      getMessage("sellers.seller.table.action.actions"),
    ],
    row: ({ id, name, onAction, metaData, status, email, phone, logo }) => (
      <Row>
        <Cell>
          <div className="seller-profile">
            <Image src={logo || userImage} size="sm" />
            <Link to={`/sellers/seller/edit/${id}`} className="user-details">
              <div className="user-name">{name}</div>
            </Link>
          </div>
        </Cell>
        {isMobileView ? (
          <>
            <Cell className="sellers-address">
              <small className="address">{metaData?.address}</small>
            </Cell>
            <Cell className="status-contact-details">
              <small className="status-contact">
                <span>{phone}</span>
                <span>{email}</span>
              </small>
            </Cell>
          </>
        ) : (
          <>
            <Cell className="status-contact-details">
              <small className="status-contact">
                <span>{phone}</span>
                <span>{email}</span>
              </small>
            </Cell>
            <Cell className="sellers-address">
              <small className="address">{metaData?.address}</small>
            </Cell>
          </>
        )}
        <Cell className="sellers-status">
          <small>{status}</small>
        </Cell>
        <Cell className="seller-actions">
          <SellerActions onAction={onAction} id={id} status={status} />
        </Cell>
      </Row>
    ),
  };
};

function AddSeller() {
  const [showPopup, setShowPopup] = useState(false);

  const showModal = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div className="text-center">
      <Popup
        heading={getMessage("sellers.seller.form.add.bulk.text")}
        show={showPopup}
        close={showModal}
        className="bulk-upload"
      >
        <SellerUpload />
      </Popup>
      <DropDown
        className="bulk-upload-dropdown"
        icon={
          <button className="button primary add-seller-dropdown-button">
            +{" "}
            <span className="text">
              {getMessage("seller.seller.form.add.text")}
            </span>
          </button>
        }
      >
        <DropDownItem>
          <Link to="/sellers/seller/add">
            {getMessage("sellers.seller.form.add.heading")}
          </Link>
        </DropDownItem>
        <DropDownItem onClick={showModal}>
          {getMessage("sellers.seller.form.add.bulk.text")}
        </DropDownItem>
      </DropDown>
    </div>
  );
}

const emptyState = {
  icon: emptyIcon,
  message: getMessage("sellers.seller.helpItems.message"),
  additionalViews: [AddSeller],
};

export default function Sellers(props) {
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.screen.width <= 480);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ListingPageWithRoutes
      helmetTitle="Zopping - Sellers"
      menu={props.menu}
      className="sellers-page"
      title="Sellers"
      addHeading={getMessage("sellers.seller.form.add.heading")}
      editHeading={getMessage("sellers.seller.form.edit.heading")}
      emptyState={emptyState}
      headerActions={AddSeller}
      api={{
        url: "/account-service/seller",
        transform: (response) => {
          return response.data?.seller;
        },
      }}
      form={{
        component: SellerForm,
        transformSubmit: (formData) => {
          let { name, description, logo, email, status, phone, address } =
            formData;
          return {
            name,
            description,
            logo,
            phone,
            email,
            status,
            metaData: {
              address,
              ...formData?.metaData,
            },
          };
        },
        transformResponse: (response) => {
          const updatedData = {
            ...response?.data,
            address: response?.data?.metaData?.address,
          };
          return updatedData;
        },
      }}
      tableProperties={tableProperties(isMobileView)}
      filters={{
        component: SellerFiltersForm,
      }}
    />
  );
}
