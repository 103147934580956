import React from "react";
import {
  BaseForm,
  Radio,
  Input,
  VALIDATION_TYPES,
} from "../../../../components/Form";

import { getMessage } from "../../../../lib/translator";

export default class SellerFiltersForm extends BaseForm {
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form className="seller-filter-form">
        <div className="form-fields">
          <Input
            label={getMessage("seller.filters.sellerName.heading")}
            placeholder={getMessage(
              "seller.filters.permissableSeller.placeholder"
            )}
            className="seller-email"
            name="email"
            type="email"
            {...this.generateStateMappers({
              stateKeys: ["email"],
              loseEmphasisOnFill: true,
              validationType: VALIDATION_TYPES.ONSUBMIT,
            })}
          />
          <Radio
            className="product-status"
            label={getMessage("product.filters.status.heading")}
            name="status"
            options={[
              {
                text: getMessage("product.filters.status.label.all"),
                value: "",
              },
              {
                text: getMessage("product.filters.status.label.enabled"),
                value: "ACTIVE",
              },
              {
                text: getMessage("product.filters.status.label.disabled"),
                value: "INACTIVE",
              },
            ]}
            {...this.generateStateMappers({
              stateKeys: ["status"],
              defaultValue: "",
            })}
          />
        </div>
        <SubmitButton>{getMessage("product.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("product.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
